import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react"
import {
  deepCopy,
  logger,
  lowercaseFirstCharacter,
  Status,
} from "../services/utilities/utility"
import { MainContext } from "./main"
import { useLazyQuery, useMutation } from "@apollo/client"
import { checkinGet, checkinList } from "../services/graphql/queries"
import Document from "../models/document"
import CheckIn from "../models/checkIn"
import CheckInsDocument from "../models/checkInsDocument"
import {
  checkinDelete,
  checkinInsert,
  upsertDocumentCheckIn,
} from "../services/graphql/mutations"
import { CheckinDifficultyLevel, CheckInKind } from "../services/config/enum"

interface CheckInsContextInterface {
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  checkInsList: CheckIn[]
  getCheckInsList: (withLoading?: boolean) => void
  hasSearched: boolean
  getCurrentCheckIn: (checkInId: string) => Promise<boolean>
  currentCheckIn: CheckIn | null
  setCurrentCheckIn: Dispatch<SetStateAction<CheckIn | null>>
  preChangesCurrentCheckIn: CheckIn | null
  doneChanges: boolean
  cancelChanges: () => void
  updatingList: boolean
  setUpdatingList: Dispatch<SetStateAction<boolean>>
  checkInsListNextToken: string | null
  loadMoreCheckIns: () => Promise<boolean>
  editMode: boolean
  setEditMode: Dispatch<SetStateAction<boolean>>
  addTranslation: (translationToAdd: string) => void
  hasError: boolean
  translationsErrors: { lang: string; hasErrors: boolean }[]
  setTranslationsErrors: Dispatch<
    SetStateAction<{ lang: string; hasErrors: boolean }[]>
  >
  copyDetailsFromDefault: (itemToCopyToIndex: number) => void
  copyBodyFromDefault: (itemToCopyToIndex: number) => void
  createCheckIn: (input: {
    checkinGroupId: string
    kind: CheckInKind
    checkinDifficultyLevel: CheckinDifficultyLevel
    repeatable: boolean
    teamId?: string
    startDate: string
    endDate: string
    geoEnabled?: boolean
    geoRadius?: number
    geoCoordinates?: { lat: number; long: number }[]
  }) => Promise<CheckIn | boolean>
  createCheckInDocument: (input: {
    parentId: string
    type: string
    checkinDocumentItems: {
      body: object[]
      default: boolean
      lang: string
      title: string
      description: string
      zone: string
    }[]
  }) => Promise<{ items: CheckInsDocument[]; parentId: string } | boolean>
  upsertCheckInDocument: () => Promise<boolean>
  deleteCheckIn: (id: string) => Promise<boolean>
}

const CheckInsContext = createContext<CheckInsContextInterface>({
  loading: true,
  setLoading: () => {},
  checkInsList: [],
  getCheckInsList: () => {},
  hasSearched: false,
  getCurrentCheckIn: async () => true,
  currentCheckIn: null,
  setCurrentCheckIn: () => {},
  preChangesCurrentCheckIn: null,
  doneChanges: false,
  cancelChanges: () => {},
  updatingList: false,
  setUpdatingList: () => {},
  checkInsListNextToken: null,
  loadMoreCheckIns: async () => true,
  editMode: true,
  setEditMode: () => {},
  addTranslation: () => {},
  hasError: false,
  translationsErrors: [],
  setTranslationsErrors: () => {},
  copyDetailsFromDefault: () => {},
  copyBodyFromDefault: () => {},
  createCheckIn: async () => false,
  createCheckInDocument: async () => true,
  upsertCheckInDocument: async () => true,
  deleteCheckIn: async () => true,
})

const CheckInsController = ({ children }: { children: ReactNode }) => {
  const { setError, setErrorMessage, panelStatus, setPanelStatus } =
    useContext(MainContext)

  // states
  const [loading, setLoading] = useState<boolean>(true) // loading
  const [checkInsList, setCheckInsList] = useState<CheckIn[]>([]) // all checkins list
  const [checkInsListNextToken, setCheckInsListNextToken] = useState<
    string | null
  >(null) // checkins list next token
  const [hasSearched, setHasSearched] = useState<boolean>(false) // if user has searched or not
  const [updatingList, setUpdatingList] = useState<boolean>(false) // if list is updating or not
  const [currentCheckIn, setCurrentCheckIn] = useState<CheckIn | null>(null) // current checkin details
  const [preChangesCurrentCheckIn, setPreChangesCurrentCheckIn] =
    useState<CheckIn | null>(null) // fetched current checkin details
  const [doneChanges, setDoneChanges] = useState<boolean>(false) // if user has done changes to checkin details
  const [editMode, setEditMode] = useState<boolean>(true) // if is edit mode or not

  // errors for checkin edit
  const [translationsErrors, setTranslationsErrors] = useState<
    { lang: string; hasErrors: boolean }[]
  >([]) // errors array for translations
  const hasError: boolean =
    translationsErrors.filter((item) => item.hasErrors).length !== 0 // if there are errors or not

  // reset all errors
  const resetErrors = () => {
    const newTranslationsErrors: { lang: string; hasErrors: boolean }[] = []
    currentCheckIn.document.items.forEach((item) => {
      newTranslationsErrors.push({ lang: item.lang, hasErrors: false })
    })
    setTranslationsErrors(newTranslationsErrors)
  }

  // queries
  const [checkinListQuery] = useLazyQuery(checkinList)
  const [checkinGetQuery] = useLazyQuery(checkinGet)

  // mutations
  const [checkinInsertMutation] = useMutation(checkinInsert)
  const [upsertDocumentCheckInMutation] = useMutation(upsertDocumentCheckIn)
  const [checkinDeleteMutation] = useMutation(checkinDelete)

  // get checkins list
  const getCheckInsList = useCallback(
    async (withLoading: boolean = true) => {
      if (withLoading) {
        setLoading(true)
        panelStatus.filter((item) => item.name === "Check-ins")[0].loading =
          true
        setPanelStatus([...panelStatus])
      }

      try {
        logger(Status.Api, "QUERY checkinList", checkinList)
        const { data } = await checkinListQuery({
          variables: {
            input: {
              limit: Math.round(window.innerHeight / 74) + 10,
            },
          },
          fetchPolicy: "no-cache",
        })
        logger(Status.Info, "checkIns list", data.checkinList.items)

        setCheckInsList(data.checkinList.items)
        setCheckInsListNextToken(data.checkinList.nextToken)

        setHasSearched(false)
        setLoading(false)
        setUpdatingList(false)

        panelStatus.filter((item) => item.name === "Check-ins")[0].status =
          "success"
        panelStatus.filter((item) => item.name === "Check-ins")[0].loading =
          false
        setPanelStatus([...panelStatus])
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          logger(Status.Error, `checkinList`, e.message)
        }
        setLoading(false)
        setUpdatingList(false)

        panelStatus.filter((item) => item.name === "Check-ins")[0].status =
          "error"
        panelStatus.filter((item) => item.name === "Check-ins")[0].loading =
          false
        setPanelStatus([...panelStatus])
      }
    },
    [setError, setErrorMessage]
  )

  // load more checkins
  const loadMoreCheckIns = async () => {
    try {
      logger(Status.Api, "QUERY checkinList", checkinList)
      const { data } = await checkinListQuery({
        variables: {
          input: {
            limit: Math.round(window.innerHeight / 74) + 10,
            nextToken: checkInsListNextToken,
          },
        },
        fetchPolicy: "no-cache",
      })
      logger(Status.Info, "checkIns list", [
        ...checkInsList,
        ...data.checkinList.items,
      ])

      setCheckInsList([...checkInsList, ...data.checkinList.items])
      setCheckInsListNextToken(data.checkinList.nextToken)

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `checkinList`, e.message)
      }
      return false
    }
  }

  // get checkin details
  const getCurrentCheckIn = useCallback(
    async (checkInId: string) => {
      try {
        logger(Status.Api, "QUERY checkinGet", checkinGet)
        const { data } = await checkinGetQuery({
          variables: { input: { id: checkInId } },
          fetchPolicy: "no-cache",
        })
        logger(Status.Info, `checkIn ${checkInId}`, data.checkinGet)

        setCurrentCheckIn(data.checkinGet)
        setPreChangesCurrentCheckIn(deepCopy(data.checkinGet))
        data.checkinGet.document.items.forEach((item: Document) => {
          translationsErrors.push({ lang: item.lang, hasErrors: false })
        })
        setTranslationsErrors([...translationsErrors])

        return true
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          logger(Status.Error, `checkinGet`, e.message)
        }
        return false
      }
    },
    [setError, setErrorMessage]
  )

  // upsert checkin document
  const upsertCheckInDocument = async () => {
    try {
      // parse data for inpout
      const currentCheckInCopy = deepCopy(currentCheckIn)
      currentCheckInCopy.document.items.forEach((item: any) => {
        const newBody = []
        if (item.body) {
          item.body.forEach((bodyItem: any) => {
            const { sliceType, __typename, ...rest } = bodyItem
            newBody.push({
              [lowercaseFirstCharacter(bodyItem.sliceType)]: {
                ...rest,
              },
            })
          })
        }
        item.body = newBody

        item.default = item.isDefault
        delete item.isDefault
        delete item.type
        delete item.updatedAt
        delete item.parentId
        delete item.__typename
      })

      const input = {
        checkinDocumentItems: currentCheckInCopy.document.items,
        parentId: currentCheckInCopy.document.parentId,
        type: "Checkin",
      }

      logger(Status.Api, "MUTATION documentUpsert", upsertDocumentCheckIn)
      const { data } = await upsertDocumentCheckInMutation({
        variables: { input: input },
      })
      logger(
        Status.Info,
        `checkIn ${data.documentUpsert.parentId} document upserted`,
        data.documentUpsert
      )

      setCurrentCheckIn((current) => {
        return {
          ...current,
          document: data.documentUpsert,
        }
      })
      setPreChangesCurrentCheckIn((current) => {
        return {
          ...current,
          document: deepCopy(data.documentUpsert),
        }
      })

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `documentUpsert`, e.message)
      }
      return false
    }
  }

  // create checkin
  const createCheckIn = async (input: {
    checkinGroupId: string
    kind: CheckInKind
    checkinDifficultyLevel: CheckinDifficultyLevel
    repeatable: boolean
    teamId?: string
    startDate: string
    endDate: string
    geoEnabled?: boolean
    geoRadius?: number
    geoCoordinates?: { lat: number; long: number }[]
  }) => {
    try {
      logger(Status.Api, "MUTATION checkinInsert", checkinInsert)
      const { data } = await checkinInsertMutation({
        variables: { input: input },
      })
      logger(Status.Info, `checkIn created`, data.checkinInsert)

      return data.checkinInsert
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `checkinInsert`, e.message)
      }
      return false
    }
  }

  // create checkin document
  const createCheckInDocument = async (input: {
    parentId: string
    type: string
    checkinDocumentItems: {
      body: object[]
      default: boolean
      lang: string
      title: string
      description: string
      zone: string
    }[]
  }) => {
    try {
      logger(Status.Api, "MUTATION documentUpsert", upsertDocumentCheckIn)
      const { data } = await upsertDocumentCheckInMutation({
        variables: { input: input },
      })
      logger(Status.Info, `document created`, data.documentUpsert)

      return data.documentUpsert
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `documentUpsert`, e.message)
      }
      return false
    }
  }

  // delete checkin
  const deleteCheckIn = async (id: string) => {
    try {
      logger(Status.Api, "MUTATION checkinDelete", checkinDelete)
      await checkinDeleteMutation({
        variables: {
          input: {
            id,
          },
        },
      })
      logger(Status.Info, `checkIn ${id} deleted`)

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `checkinDelete`, e.message)
      }
      return false
    }
  }

  // add translation
  const addTranslation = (translationToAdd: string) => {
    const documentToAdd: CheckInsDocument = {
      body: [],
      isDefault: false,
      lang: translationToAdd,
      title: "Title",
      description: "Description",
      zone: "Zone",
      type: "Checkin",
    }

    translationsErrors.push({
      lang: translationToAdd,
      hasErrors: false,
    })
    setTranslationsErrors([...translationsErrors])

    currentCheckIn.document.items.push(documentToAdd)
    setCurrentCheckIn({ ...currentCheckIn })
  }

  // copy details from default translation
  const copyDetailsFromDefault = (itemToCopyToIndex: number) => {
    const defaultItem = currentCheckIn.document.items.filter(
      (item) => item.isDefault
    )[0]

    currentCheckIn.document.items[itemToCopyToIndex].title = defaultItem.title
    currentCheckIn.document.items[itemToCopyToIndex].description =
      defaultItem.description
    currentCheckIn.document.items[itemToCopyToIndex].zone = defaultItem.zone
    currentCheckIn.document.items[itemToCopyToIndex].body = defaultItem.body

    setCurrentCheckIn({ ...currentCheckIn })
  }

  // copy body from default translation
  const copyBodyFromDefault = (itemToCopyToIndex: number) => {
    const defaultItem = currentCheckIn.document.items.filter(
      (item) => item.isDefault
    )[0]

    currentCheckIn.document.items[itemToCopyToIndex].body = defaultItem.body

    setCurrentCheckIn({ ...currentCheckIn })
  }

  // check if user has done changes to details
  useEffect(() => {
    if (currentCheckIn && preChangesCurrentCheckIn) {
      const currentCheckInCopy: CheckIn = deepCopy(currentCheckIn)
      const preChangesCurrentCheckInCopy: CheckIn = deepCopy(
        preChangesCurrentCheckIn
      )

      if (
        JSON.stringify(currentCheckInCopy) ===
        JSON.stringify(preChangesCurrentCheckInCopy)
      ) {
        setDoneChanges(false)
      } else {
        setDoneChanges(true)
      }
    }
  }, [currentCheckIn, preChangesCurrentCheckIn])

  // cancel details changes
  const cancelChanges = () => {
    setCurrentCheckIn(deepCopy(preChangesCurrentCheckIn))
    resetErrors()
  }

  // add update function to panel status
  useEffect(() => {
    panelStatus.filter((item) => item.name === "Check-ins")[0].updateFunction =
      getCheckInsList
  }, [])

  return (
    <CheckInsContext.Provider
      value={{
        loading,
        setLoading,
        checkInsList,
        getCheckInsList,
        hasSearched,
        getCurrentCheckIn,
        currentCheckIn,
        setCurrentCheckIn,
        preChangesCurrentCheckIn,
        doneChanges,
        cancelChanges,
        updatingList,
        setUpdatingList,
        checkInsListNextToken,
        loadMoreCheckIns,
        editMode,
        setEditMode,
        addTranslation,
        hasError,
        translationsErrors,
        setTranslationsErrors,
        copyDetailsFromDefault,
        copyBodyFromDefault,
        createCheckIn,
        createCheckInDocument,
        upsertCheckInDocument,
        deleteCheckIn,
      }}
    >
      {children}
    </CheckInsContext.Provider>
  )
}

export { CheckInsController, CheckInsContext }
