import { Alert, LinearProgress, Snackbar } from "@mui/material"
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import ListContainer from "../../components/global/listContainer"
import ListTable from "../../components/global/listTable"
import TableSpinner from "../../components/global/tableSpinner"
import { MainContext } from "../../controllers/main"
import { useLocation } from "react-router-dom"
import { CheckInGroupsContext } from "../../controllers/checkInGroups"
import CheckInGroupsListRow from "../../components/checkInGroups/checkInGroupsListRow"
import CheckInGroupsListTopBar from "../../components/checkInGroups/checkInGroupsListTopBar"
import CreateCheckInGroupDialog from "../../components/checkInGroups/createCheckInGroupDialog"
import CheckInGroup from "../../models/checkInGroup"

const CheckInGroupsList = ({
  forDialog,
  selectedCheckInGroup,
  setSelectedCheckInGroup,
}: {
  forDialog?: boolean
  selectedCheckInGroup?: CheckInGroup
  setSelectedCheckInGroup?: Dispatch<SetStateAction<CheckInGroup>>
}) => {
  const { showPadding } = useContext(MainContext)
  const {
    loading,
    updatingList,
    setUpdatingList,
    checkInGroupsList,
    getCheckInGroupsList,
    checkInGroupsListNextToken,
    loadMoreCheckInGroups,
    hasSearched,
  } = useContext(CheckInGroupsContext)
  const location = useLocation()

  // list background update
  const backgroundUpdate = () => {
    if (!checkInGroupsList.length) {
      getCheckInGroupsList()
    } else if (!loading && !hasSearched) {
      setUpdatingList(true)
      getCheckInGroupsList(false)
    }
  } // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    backgroundUpdate()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // handle create dialog
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)

  const handleDialogChange = useCallback(() => {
    setDialogOpen(!dialogOpen)
  }, [dialogOpen])

  // load more button loading
  const [loadMoreLoading, setLoadMoreLoading] = useState<boolean>(false)

  // handle item deleted feedback
  const [itemDeletedFeedbackOpen, setItemDeletedFeedbackOpen] =
    useState<boolean>(false)

  useEffect(() => {
    if (location.state && (location.state as any).checkInGroupDeleted) {
      setItemDeletedFeedbackOpen(true)
    }
  }, [])

  return (
    <ListContainer style={{ position: "relative" }}>
      {updatingList && (
        <LinearProgress
          style={{
            position: "absolute",
            top: 88,
            width: "100%",
            zIndex: 10,
          }}
        />
      )}
      <CheckInGroupsListTopBar
        addButtonOnClick={handleDialogChange}
        forDialog={forDialog}
      />
      {loading ? (
        <TableSpinner
          height={
            showPadding === "yes"
              ? "calc(100vh - 150px)"
              : "calc(100vh - 118px)"
          }
        />
      ) : (
        <ListTable
          height={
            showPadding === "yes"
              ? "calc(100vh - 150px)"
              : "calc(100vh - 118px)"
          }
          headingItems={[
            "Title & ID",
            "Translations",
            "Status",
            "Team",
            "Updated",
          ]}
          nextToken={checkInGroupsListNextToken}
          loadingMoreItems={loadMoreLoading}
          setLoadingMoreItems={setLoadMoreLoading}
          loadMore={loadMoreCheckInGroups}
          tableBody={checkInGroupsList.map((item) => (
            <CheckInGroupsListRow
              key={item.id}
              item={item}
              selectedCheckInGroup={selectedCheckInGroup}
              setSelectedCheckInGroup={setSelectedCheckInGroup}
              forDialog={forDialog}
            />
          ))}
        />
      )}
      <CreateCheckInGroupDialog
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        handleDialogChange={handleDialogChange}
        topBarText="Add Check-in Group"
      />
      <Snackbar
        open={itemDeletedFeedbackOpen}
        onClose={() => {
          setItemDeletedFeedbackOpen(false)
        }}
        autoHideDuration={3000}
      >
        <Alert severity="success">Check-in Group deleted</Alert>
      </Snackbar>
    </ListContainer>
  )
}

export default CheckInGroupsList
