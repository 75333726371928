import {
  Button,
  Card,
  CardContent,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { useContext, useState } from "react"
import { MainContext } from "../../controllers/main"
import { CheckInGroupsContext } from "../../controllers/checkInGroups"
import { AddCircleRounded, OpenInNewRounded } from "@mui/icons-material"
import CopyToClipboardButton from "../global/copyToClipboardButton"
import ISODatePicker from "../global/isoDatePicker"
import CreateCheckInDialog from "../checkIns/createCheckInDialog"
import { CheckInsContext } from "../../controllers/checkIns"

const CheckInGroupGeneralEdit = () => {
  const { showPadding } = useContext(MainContext)
  const {
    currentCheckInGroup,
    setCurrentCheckInGroup,
    editMode,
    currentCheckInGroupCheckInsList,
    setCurrentCheckInGroupCheckInsList,
  } = useContext(CheckInGroupsContext)
  const { setLoading: setCheckInsLoading } = useContext(CheckInsContext)

  // create checkIn dialog
  const [createCheckInDialogOpen, setCreateCheckInDialogOpen] =
    useState<boolean>(false)

  const handleCreateCheckInDialogChange = () => {
    setCreateCheckInDialogOpen((current) => !current)
  }

  return (
    <CardContent
      style={{
        height:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        maxHeight:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        overflowY: "scroll",
      }}
    >
      <Stack spacing={2}>
        <Typography variant="h6" className="card-title">
          Configuration
        </Typography>
        <Stack gap={2} direction="row">
          <ISODatePicker
            label="Start date"
            date={currentCheckInGroup.startDate}
            setDate={(newDate: string) => {
              setCurrentCheckInGroup((current) => {
                return {
                  ...current,
                  startDate: newDate.split("T")[0],
                }
              })
            }}
            hour={12}
            disabled={!editMode}
            readOnly
          />
          <ISODatePicker
            label="End date"
            date={currentCheckInGroup.endDate}
            setDate={(newDate: string) => {
              setCurrentCheckInGroup((current) => {
                return {
                  ...current,
                  endDate: newDate.split("T")[0],
                }
              })
            }}
            hour={12}
            disabled={!editMode}
            readOnly
          />
        </Stack>
        <TextField
          fullWidth
          label="Team"
          size="small"
          value={
            currentCheckInGroup.team &&
            currentCheckInGroup.team.id !== "team_default"
              ? currentCheckInGroup.team.document.items.find(
                  (item) => item.isDefault
                ).title
              : "Global (App)"
          }
          disabled={!editMode}
          InputProps={{
            readOnly: true,
            endAdornment:
              currentCheckInGroup.team &&
              currentCheckInGroup.team.id !== "team_default" ? (
                <Button
                  sx={{ paddingInline: 3 }}
                  onClick={() =>
                    window.open(`/teams/${currentCheckInGroup.team.id}`)
                  }
                >
                  <OpenInNewRounded
                    style={{
                      fontSize: 15,
                    }}
                  />
                </Button>
              ) : null,
          }}
        />
        <Typography variant="h6" className="card-title">
          Check-ins
        </Typography>
        <Stack gap={1}>
          {currentCheckInGroupCheckInsList.map((checkIn) => (
            <Card key={checkIn.id} style={{ width: "100%" }}>
              <CardContent
                style={{ padding: 16, paddingTop: 12, paddingBottom: 12 }}
              >
                <Stack direction="row" alignItems="center">
                  <Stack sx={{ gap: -5, flex: 1 }}>
                    <Typography
                      style={{
                        fontSize: 15,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "100%",
                      }}
                    >
                      {
                        checkIn.document.items.find((item) => item.isDefault)
                          .title
                      }
                    </Typography>
                    <Stack
                      direction="row"
                      alignItems="center"
                      style={{ marginLeft: -3 }}
                    >
                      <CopyToClipboardButton
                        textToCopy={checkIn.id}
                        size="small"
                      />
                      <Typography
                        variant="caption"
                        style={{
                          fontSize: 10,
                          color: "gray",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {checkIn.id}
                      </Typography>
                    </Stack>
                  </Stack>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation()
                      window.open(`/checkins/${checkIn.id}`)
                    }}
                  >
                    <OpenInNewRounded style={{ fontSize: 18 }} />
                  </IconButton>
                </Stack>
              </CardContent>
            </Card>
          ))}
        </Stack>
        <Button
          onClick={() => {
            setCheckInsLoading(false)
            handleCreateCheckInDialogChange()
          }}
          variant="outlined"
          endIcon={<AddCircleRounded />}
          fullWidth
          disabled={!editMode}
          style={{ height: 40 }}
        >
          Add Check-in
        </Button>
      </Stack>
      {/* create checkIn */}
      <CreateCheckInDialog
        dialogOpen={createCheckInDialogOpen}
        setDialogOpen={setCreateCheckInDialogOpen}
        handleDialogChange={handleCreateCheckInDialogChange}
        topBarText="Add Check-in"
        preSelectedCheckInGroup={currentCheckInGroup}
        setCurrentCheckInGroupCheckInsList={setCurrentCheckInGroupCheckInsList}
      />
    </CardContent>
  )
}

export default CheckInGroupGeneralEdit
