import { Route, Routes } from "react-router-dom"
import Dashboard from "../../views/dashboard/dashboard"
import EpisodeEdit from "../../views/episode/episodeEdit"
import EpisodesList from "../../views/episode/episodesList"
import JourneyEdit from "../../views/journey/journeyEdit"
import JourneysList from "../../views/journey/journeysList"
import MediaList from "../../views/mediaLibrary/mediaList"
import TeamsList from "../../views/team/teamsList"
import FreeJourneysRelationEdit from "../../views/freeJourneysRelation/freeJourneysRelationEdit"
import DailyEpisodesEdit from "../../views/dailyEpisode/dailyEpisodesEdit"
import ChannelsList from "../../views/channel/channelsList"
import ChannelEdit from "../../views/channel/channelEdit"
import NewsList from "../../views/news/newsList"
import NewsEdit from "../../views/news/newsEdit"
import ActionsList from "../../views/action/actionsList"
import ActionEdit from "../../views/action/actionEdit"
import CurrentNewsList from "../../views/currentNews/currentNewsList"
import ActionGroupsList from "../../views/actionGroup/actionGroupsList"
import ActionGroupEdit from "../../views/actionGroup/actionGroupEdit"
import BadgesList from "../../views/badge/badgesList"
import BadgeEdit from "../../views/badge/badgeEdit"
import ChallengesList from "../../views/challenge/challengesList"
import ChallengeEdit from "../../views/challenge/challengeEdit"
import ChallengeLeaderboardUsers from "../../views/challenge/challengeLeaderboardUsers"
import ChallengeReport from "../../views/challenge/challengeReport"
import ChallengeLeaderboardGroup from "../../views/challenge/challengeLeaderboardGroup"
import TagsList from "../../views/tags/tagsList"
import CategoryEdit from "../../views/tags/categoryEdit"
import MainCategoryEdit from "../../views/tags/mainCategoryEdit"
import SdgEdit from "../../views/tags/sdgEdit"
import SdgTargetEdit from "../../views/tags/sdgTargetEdit"
import TopicEdit from "../../views/tags/topicEdit"
import ChallengeLeaderboardGroups from "../../views/challenge/challengeLeaderboardGroups"
import TranslationJobsList from "../../views/translationJobs/translationJobsList"
import TranslationJobDetails from "../../views/translationJobs/translationJobDetails"
import TeamEdit from "../../views/team/teamEdit"
import MembersList from "../../views/team/membersList"
import TeamActivationCodesList from "../../views/team/teamActivationCodesList"
import Tools from "../../views/tools/tools"
import TeamReport from "../../views/team/teamReport"
import TeamReportNew from "../../views/team/teamReportNew"
import ProductsList from "../../views/marketplace/productsList"
import ProductEdit from "../../views/marketplace/productEdit"
import NftCatalogsList from "../../views/nft/nftCatalogsList"
import NftCatalogEdit from "../../views/nft/nftCatalogEdit"
import ActivationCodesList from "../../views/activationCode/activationCodesList"
import MissionsList from "../../views/mission/missionsList"
import MissionEdit from "../../views/mission/missionEdit"
import LeaguesList from "../../views/league/leaguesList"
import LeagueEdit from "../../views/league/leagueEdit"
import SurveysList from "../../views/survey/surveysList"
import SurveyEdit from "../../views/survey/surveyEdit"
import NewsQuizzesList from "../../views/news/newsQuizzes/newsQuizzesList"
import NewsQuizEdit from "../../views/news/newsQuizzes/newsQuizEdit"
import CheckInsList from "../../views/checkIn/checkInsList"
import CheckInEdit from "../../views/checkIn/checkInEdit"
import CheckInGroupsList from "../../views/checkInGroup/checkInGroupsList"
import CheckInGroupEdit from "../../views/checkInGroup/checkInGroupEdit"

const AppRoutes = () => {
  return (
    <Routes>
      {/* dashboard */}
      <Route path="/" element={<Dashboard />} />
      {/* teams */}
      <Route path="/teams" element={<TeamsList />} />
      <Route path="/teams/:teamId" element={<TeamEdit />} />
      <Route path="/teams/:teamId/members" element={<MembersList />} />
      <Route
        path="/teams/:teamId/activationcodes"
        element={<TeamActivationCodesList />}
      />
      <Route path="/teams/:teamId/report" element={<TeamReport />} />
      <Route path="/teams/:teamId/reportnew" element={<TeamReportNew />} />
      {/* episodes */}
      <Route path="/episodes" element={<EpisodesList />} />
      <Route path="/episodes/:episodeId" element={<EpisodeEdit />} />
      {/* daily episodes */}
      <Route path="/dailyepisodes" element={<DailyEpisodesEdit />} />
      {/* journeys */}
      <Route path="/journeys" element={<JourneysList />} />
      <Route path="/journeys/:journeyId" element={<JourneyEdit />} />
      {/* free journeys */}
      <Route path="/freejourneys" element={<FreeJourneysRelationEdit />} />
      {/* media library */}
      <Route path="/medialibrary" element={<MediaList />} />
      {/* channels */}
      <Route path="/channels" element={<ChannelsList />} />
      <Route path="/channels/:channelId" element={<ChannelEdit />} />
      {/* news */}
      <Route path="/news" element={<NewsList />} />
      <Route path="/news/:newsId" element={<NewsEdit />} />
      <Route path="/news/:newsId/quizzes" element={<NewsQuizzesList />} />
      <Route path="/news/:newsId/quizzes/:quizId" element={<NewsQuizEdit />} />
      {/* current news */}
      <Route path="/currentnews" element={<CurrentNewsList />} />
      <Route path="/currentnews/:newsId" element={<NewsEdit />} />
      {/* actions */}
      <Route path="/actions" element={<ActionsList />} />
      <Route path="/actions/:actionId" element={<ActionEdit />} />
      {/* action groups */}
      <Route path="/series" element={<ActionGroupsList />} />
      <Route path="/series/:actionGroupId" element={<ActionGroupEdit />} />
      {/* badges */}
      <Route path="/badges" element={<BadgesList />} />
      <Route path="/badges/:badgeId" element={<BadgeEdit />} />
      {/* challenges */}
      <Route path="/challenges" element={<ChallengesList />} />
      <Route path="/challenges/:challengeId" element={<ChallengeEdit />} />
      <Route
        path="/challenges/:challengeId/leaderboard/users"
        element={<ChallengeLeaderboardUsers />}
      />
      <Route
        path="/challenges/:challengeId/leaderboard/groups"
        element={<ChallengeLeaderboardGroups />}
      />
      <Route
        path="/challenges/:challengeId/leaderboard/groups/:groupId"
        element={<ChallengeLeaderboardGroup />}
      />
      <Route
        path="/challenges/:challengeId/report"
        element={<ChallengeReport />}
      />
      {/* marketplace */}
      <Route path="/marketplace" element={<ProductsList />} />
      <Route path="/marketplace/:productId" element={<ProductEdit />} />
      {/* nfts */}
      <Route path="/nftcatalogs" element={<NftCatalogsList />} />
      <Route path="/nftcatalogs/:catalogId" element={<NftCatalogEdit />} />
      {/* activation codes */}
      <Route path="/activationcodes" element={<ActivationCodesList />} />
      {/* notifications */}
      {/* <Route path="/templates" element={<TemplatesList />} />
      <Route path="/templates/:templateId" element={<TemplateEdit />} /> */}
      {/* missions */}
      <Route path="/missions" element={<MissionsList />} />
      <Route path="/missions/:missionId" element={<MissionEdit />} />
      {/* checkins */}
      <Route path="/checkins" element={<CheckInsList />} />
      <Route path="/checkins/:checkInId" element={<CheckInEdit />} />
      {/* checkin groups */}
      <Route path="/checkingroups" element={<CheckInGroupsList />} />
      <Route
        path="/checkingroups/:checkInGroupId"
        element={<CheckInGroupEdit />}
      />
      {/* leagues */}
      <Route path="/leagues" element={<LeaguesList />} />
      <Route path="/leagues/:leagueId" element={<LeagueEdit />} />
      {/* surveys */}
      <Route path="/surveys" element={<SurveysList />} />
      <Route path="/surveys/:surveyId" element={<SurveyEdit />} />
      {/* tags */}
      <Route path="/tags" element={<TagsList />} />
      <Route path="/tags/category/:categoryId" element={<CategoryEdit />} />
      <Route
        path="/tags/maincategory/:mainCategoryId"
        element={<MainCategoryEdit />}
      />
      <Route path="/tags/sdg/:sdgId" element={<SdgEdit />} />
      <Route path="/tags/sdgtarget/:sdgTargetId" element={<SdgTargetEdit />} />
      <Route path="/tags/topic/:topicId" element={<TopicEdit />} />
      {/* translation jobs */}
      <Route path="/translationJobs" element={<TranslationJobsList />} />
      <Route
        path="/translationJobs/:jobId"
        element={<TranslationJobDetails />}
      />
      {/* tools */}
      <Route path="/tools" element={<Tools />} />
    </Routes>
  )
}

export default AppRoutes
