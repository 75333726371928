import {
  Chip,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material"
import CheckIn from "../../models/checkIn"
import { useContext, useRef, useState } from "react"
import { MainContext } from "../../controllers/main"
import { useNavigate } from "react-router-dom"
import QRCode from "react-qr-code"
import TitleTableCell from "../global/titleTableCell"
import TranslationsStack from "../global/translationsStack"
import {
  calculateTimeElapsed,
  capitalizeFirstCharacter,
} from "../../services/utilities/utility"
import { DownloadRounded, OpenInNewRounded } from "@mui/icons-material"
import html2canvas from "html2canvas"
import { CheckinDifficultyLevel } from "../../services/config/enum"

const CheckInsListRow = ({ item }: { item: CheckIn }) => {
  const navigate = useNavigate()
  const { cmdPressed, setAnimation } = useContext(MainContext)

  // qr code download
  const [showQrDownloadButton, setShowQrDownloadButton] =
    useState<boolean>(false)
  const qrCodeRef = useRef<HTMLDivElement>(null)

  const handleDownloadQRCode = async () => {
    if (!qrCodeRef.current) return

    try {
      const canvas = await html2canvas(qrCodeRef.current, {
        backgroundColor: "white",
        scale: 22.86,
      })

      const link = document.createElement("a")
      link.download = `qr-code-${item.id}.png`
      link.href = canvas.toDataURL("image/png")
      link.click()
    } catch (error) {
      console.error("Error downloading QR code:", error)
    }
  }

  return (
    <TableRow
      key={item.id}
      hover
      style={{
        cursor: "pointer",
      }}
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
      }}
      onClick={() => {
        if (cmdPressed) {
          window.open(`${window.location.href}/${item.id}`)
        } else {
          setAnimation(false)
          setTimeout(() => {
            navigate(`/checkins/${item.id}`)
          }, 250)
        }
      }}
      onMouseEnter={() => setShowQrDownloadButton(true)}
      onMouseLeave={() => setShowQrDownloadButton(false)}
    >
      <TableCell style={{ minWidth: 92 }}>
        <div ref={qrCodeRef} style={{ width: 42, height: 42 }}>
          <QRCode
            value={`${process.env.REACT_APP_CHECKINS_QR_REDIRECT_URL}?id=${
              item.id
            }&checkinGroupId=${item.checkinGroup.id}${
              item.team && item.team.id !== "team_default"
                ? `&teamId=${item.team.id}`
                : ""
            }`}
            size={42}
          />
        </div>
      </TableCell>
      <TitleTableCell
        title={
          item.document.items.filter(
            (documentItem) => documentItem.isDefault
          )[0].title
        }
        id={item.id}
      />
      <TableCell>
        <TranslationsStack documentItems={item.document.items} />
      </TableCell>
      <TableCell>
        {new Date(item.startDate) > new Date() ? (
          <Chip size="small" label="Not started" color="default" />
        ) : new Date(item.endDate) >= new Date() ? (
          <Chip size="small" label="Current" color="success" />
        ) : (
          <Chip size="small" label="Ended" color="error" />
        )}
      </TableCell>
      <TableCell>
        <Chip
          label={capitalizeFirstCharacter(item.difficultyLevel)}
          size="small"
          style={{
            backgroundColor:
              item.difficultyLevel === CheckinDifficultyLevel.easy
                ? "#BAE1CB"
                : item.difficultyLevel === CheckinDifficultyLevel.medium
                ? "#F9DCA9"
                : "#F9A9A9",
          }}
        />
      </TableCell>
      <TableCell>
        <Stack gap={0.5} direction="row" alignItems="center">
          <Typography
            variant="caption"
            style={{
              whiteSpace: "nowrap",
              maxWidth: 180,
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {
              item.checkinGroup.document.items.find((item) => item.isDefault)
                .title
            }
          </Typography>
          <IconButton
            onClick={(e) => {
              e.stopPropagation()
              window.open(`/checkingroups/${item.checkinGroup.id}`)
            }}
          >
            <OpenInNewRounded style={{ fontSize: 14 }} />
          </IconButton>
        </Stack>
      </TableCell>
      <TableCell>
        <Stack gap={0.5} direction="row" alignItems="center">
          <Typography
            variant="caption"
            style={{
              whiteSpace: "nowrap",
              maxWidth: 180,
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {item.team && item.team.id !== "team_default"
              ? item.team.document.items.find((item) => item.isDefault).title
              : "Global (App)"}
          </Typography>
          {item.team && item.team.id !== "team_default" ? (
            <IconButton
              onClick={(e) => {
                e.stopPropagation()
                window.open(`/teams/${item.team.id}`)
              }}
            >
              <OpenInNewRounded style={{ fontSize: 14 }} />
            </IconButton>
          ) : null}
        </Stack>
      </TableCell>
      <TableCell style={{ minWidth: 120 }}>
        <Typography variant="caption">
          {calculateTimeElapsed(item.updatedAt)}
        </Typography>
      </TableCell>
      <TableCell style={{ minWidth: 120 }}>
        <IconButton
          style={{ opacity: showQrDownloadButton ? 1 : 0, transition: "200ms" }}
          onClick={(e) => {
            e.stopPropagation()
            handleDownloadQRCode()
          }}
        >
          <DownloadRounded />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

export default CheckInsListRow
