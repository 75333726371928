const constraints = {
  news: {
    title: {
      max: 60,
      min: 3,
    },
    tagline: {
      max: 60,
    },
  },
  action: {
    title: {
      max: 60,
      min: 3,
    },
  },
  actionGroup: {
    title: {
      max: 60,
      min: 3,
    },
  },
  badge: {
    title: {
      max: 60,
      min: 3,
    },
  },
  challenge: {
    title: {
      max: 120,
      min: 3,
    },
    prizeTitle: {
      max: 60,
    },
    prizeDescription: {
      max: 120,
    },
  },
  product: {
    title: {
      max: 60,
      min: 3,
    },
  },
  team: {
    title: {
      max: 60,
      min: 3,
    },
    homeTitle: {
      max: 60,
    },
    homeSubtitle: {
      max: 120,
    },
  },
  checkIn: {
    title: {
      min: 1,
    },
    description: {
      min: 1,
    },
    zone: {
      min: 1,
    },
  },
  checkInGroup: {
    title: {
      min: 1,
    },
    description: {
      min: 1,
    },
  },
}

export default constraints
