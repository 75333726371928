import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react"
import {
  deepCopy,
  logger,
  lowercaseFirstCharacter,
  Status,
} from "../services/utilities/utility"
import { MainContext } from "./main"
import { useLazyQuery, useMutation } from "@apollo/client"
import {
  checkinGroupGet,
  checkinGroupList,
  checkinListByGroupId,
} from "../services/graphql/queries"
import Document from "../models/document"
import CheckInGroup from "../models/checkInGroup"
import CheckInGroupsDocument from "../models/checkInGroupsDocument"
import {
  checkinGroupDelete,
  checkinGroupInsert,
  upsertDocumentCheckInGroup,
} from "../services/graphql/mutations"
import CheckIn from "../models/checkIn"

interface CheckInGroupsContextInterface {
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  checkInGroupsList: CheckInGroup[]
  getCheckInGroupsList: (withLoading?: boolean) => void
  hasSearched: boolean
  getCurrentCheckInGroup: (checkInGroupId: string) => Promise<boolean>
  currentCheckInGroup: CheckInGroup | null
  setCurrentCheckInGroup: Dispatch<SetStateAction<CheckInGroup | null>>
  preChangesCurrentCheckInGroup: CheckInGroup | null
  currentCheckInGroupCheckInsList: CheckIn[]
  setCurrentCheckInGroupCheckInsList: Dispatch<SetStateAction<CheckIn[]>>
  doneChanges: boolean
  cancelChanges: () => void
  updatingList: boolean
  setUpdatingList: Dispatch<SetStateAction<boolean>>
  checkInGroupsListNextToken: string | null
  loadMoreCheckInGroups: () => Promise<boolean>
  editMode: boolean
  setEditMode: Dispatch<SetStateAction<boolean>>
  addTranslation: (translationToAdd: string) => void
  hasError: boolean
  translationsErrors: { lang: string; hasErrors: boolean }[]
  setTranslationsErrors: Dispatch<
    SetStateAction<{ lang: string; hasErrors: boolean }[]>
  >
  copyDetailsFromDefault: (itemToCopyToIndex: number) => void
  copyBodyFromDefault: (itemToCopyToIndex: number) => void
  createCheckInGroup: (input: {
    startDate: string
    endDate: string
    teamId?: string
  }) => Promise<string | boolean>
  createCheckInGroupDocument: (input: {
    parentId: string
    type: string
    checkinGroupDocumentItems: {
      body: object[]
      default: boolean
      lang: string
      title: string
      description: string
    }[]
  }) => Promise<boolean>
  upsertCheckInGroupDocument: () => Promise<boolean>
  deleteCheckInGroup: (id: string) => Promise<boolean>
}

const CheckInGroupsContext = createContext<CheckInGroupsContextInterface>({
  loading: true,
  setLoading: () => {},
  checkInGroupsList: [],
  getCheckInGroupsList: () => {},
  hasSearched: false,
  getCurrentCheckInGroup: async () => true,
  currentCheckInGroup: null,
  setCurrentCheckInGroup: () => {},
  preChangesCurrentCheckInGroup: null,
  currentCheckInGroupCheckInsList: [],
  setCurrentCheckInGroupCheckInsList: () => {},
  doneChanges: false,
  cancelChanges: () => {},
  updatingList: false,
  setUpdatingList: () => {},
  checkInGroupsListNextToken: null,
  loadMoreCheckInGroups: async () => true,
  editMode: true,
  setEditMode: () => {},
  addTranslation: () => {},
  hasError: false,
  translationsErrors: [],
  setTranslationsErrors: () => {},
  copyDetailsFromDefault: () => {},
  copyBodyFromDefault: () => {},
  createCheckInGroup: async () => false,
  createCheckInGroupDocument: async () => true,
  upsertCheckInGroupDocument: async () => true,
  deleteCheckInGroup: async () => true,
})

const CheckInGroupsController = ({ children }: { children: ReactNode }) => {
  const { setError, setErrorMessage, panelStatus, setPanelStatus } =
    useContext(MainContext)

  // states
  const [loading, setLoading] = useState<boolean>(true) // loading
  const [checkInGroupsList, setCheckInGroupsList] = useState<CheckInGroup[]>([]) // all checkin groups list
  const [checkInGroupsListNextToken, setCheckInGroupsListNextToken] = useState<
    string | null
  >(null) // checkin groups list next token
  const [hasSearched, setHasSearched] = useState<boolean>(false) // if user has searched or not
  const [updatingList, setUpdatingList] = useState<boolean>(false) // if list is updating or not
  const [currentCheckInGroup, setCurrentCheckInGroup] =
    useState<CheckInGroup | null>(null) // current checkin group details
  const [preChangesCurrentCheckInGroup, setPreChangesCurrentCheckInGroup] =
    useState<CheckInGroup | null>(null) // fetched current checkin group details
  const [currentCheckInGroupCheckInsList, setCurrentCheckInGroupCheckInsList] =
    useState<CheckIn[]>([]) // current checkin group checkins list
  const [doneChanges, setDoneChanges] = useState<boolean>(false) // if user has done changes to checkin group details
  const [editMode, setEditMode] = useState<boolean>(true) // if is edit mode or not

  // errors for checkin edit
  const [translationsErrors, setTranslationsErrors] = useState<
    { lang: string; hasErrors: boolean }[]
  >([]) // errors array for translations
  const hasError: boolean =
    translationsErrors.filter((item) => item.hasErrors).length !== 0 // if there are errors or not

  // reset all errors
  const resetErrors = () => {
    const newTranslationsErrors: { lang: string; hasErrors: boolean }[] = []
    currentCheckInGroup.document.items.forEach((item) => {
      newTranslationsErrors.push({ lang: item.lang, hasErrors: false })
    })
    setTranslationsErrors(newTranslationsErrors)
  }

  // queries
  const [checkinGroupListQuery] = useLazyQuery(checkinGroupList)
  const [checkinListByGroupIdQuery] = useLazyQuery(checkinListByGroupId)
  const [checkinGroupGetQuery] = useLazyQuery(checkinGroupGet)

  // mutations
  const [checkinGroupInsertMutation] = useMutation(checkinGroupInsert)
  const [upsertDocumentCheckInGroupMutation] = useMutation(
    upsertDocumentCheckInGroup
  )
  const [checkinGroupDeleteMutation] = useMutation(checkinGroupDelete)

  // get checkin groups list
  const getCheckInGroupsList = useCallback(
    async (withLoading: boolean = true) => {
      if (withLoading) {
        setLoading(true)
        panelStatus.filter(
          (item) => item.name === "Check-in Groups"
        )[0].loading = true
        setPanelStatus([...panelStatus])
      }

      try {
        logger(Status.Api, "QUERY checkinGroupList", checkinGroupList)
        const { data } = await checkinGroupListQuery({
          variables: {
            input: {
              limit: Math.round(window.innerHeight / 74) + 10,
            },
          },
          fetchPolicy: "no-cache",
        })
        logger(Status.Info, "checkIn groups list", data.checkinGroupList.items)

        setCheckInGroupsList(data.checkinGroupList.items)
        setCheckInGroupsListNextToken(data.checkinGroupList.nextToken)

        setHasSearched(false)
        setLoading(false)
        setUpdatingList(false)

        panelStatus.filter(
          (item) => item.name === "Check-in Groups"
        )[0].status = "success"
        panelStatus.filter(
          (item) => item.name === "Check-in Groups"
        )[0].loading = false
        setPanelStatus([...panelStatus])
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          logger(Status.Error, `checkinGroupList`, e.message)
        }
        setLoading(false)
        setUpdatingList(false)

        panelStatus.filter(
          (item) => item.name === "Check-in Groups"
        )[0].status = "error"
        panelStatus.filter(
          (item) => item.name === "Check-in Groups"
        )[0].loading = false
        setPanelStatus([...panelStatus])
      }
    },
    [setError, setErrorMessage]
  )

  // load more checkin groups
  const loadMoreCheckInGroups = async () => {
    try {
      logger(Status.Api, "QUERY checkinGroupList", checkinGroupList)
      const { data } = await checkinGroupListQuery({
        variables: {
          input: {
            limit: Math.round(window.innerHeight / 74) + 10,
            nextToken: checkInGroupsListNextToken,
          },
        },
        fetchPolicy: "no-cache",
      })
      logger(Status.Info, "checkIn groups list", [
        ...checkInGroupsList,
        ...data.checkinGroupList.items,
      ])

      setCheckInGroupsList([
        ...checkInGroupsList,
        ...data.checkinGroupList.items,
      ])
      setCheckInGroupsListNextToken(data.checkinGroupList.nextToken)

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `checkinGroupList`, e.message)
      }
      return false
    }
  }

  // get checkin group details
  const getCurrentCheckInGroup = useCallback(
    async (checkInGroupId: string) => {
      try {
        logger(Status.Api, "QUERY checkinGroupGet", checkinGroupGet)
        logger(Status.Api, "QUERY checkinListByGroupId", checkinListByGroupId)
        const result = await Promise.all([
          checkinGroupGetQuery({
            variables: { input: { id: checkInGroupId } },
            fetchPolicy: "no-cache",
          }),
          checkinListByGroupIdQuery({
            variables: { input: { groupId: checkInGroupId, limit: 100 } },
            fetchPolicy: "no-cache",
          }),
        ])
        logger(
          Status.Info,
          `checkIn group ${checkInGroupId}`,
          result[0].data.checkinGroupGet
        )
        logger(
          Status.Info,
          "checkIn group checkIns list",
          result[1].data.checkinListByGroupId.items
        )

        setCurrentCheckInGroup(result[0].data.checkinGroupGet)
        setPreChangesCurrentCheckInGroup(
          deepCopy(result[0].data.checkinGroupGet)
        )
        result[0].data.checkinGroupGet.document.items.forEach(
          (item: Document) => {
            translationsErrors.push({ lang: item.lang, hasErrors: false })
          }
        )
        setTranslationsErrors([...translationsErrors])

        setCurrentCheckInGroupCheckInsList(
          result[1].data.checkinListByGroupId.items
        )

        return true
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          logger(Status.Error, `checkinGroupGet`, e.message)
        }
        return false
      }
    },
    [setError, setErrorMessage]
  )

  // upsert checkin group document
  const upsertCheckInGroupDocument = async () => {
    try {
      // parse data for inpout
      const currentCheckInGroupCopy = deepCopy(currentCheckInGroup)
      currentCheckInGroupCopy.document.items.forEach((item: any) => {
        const newBody = []
        if (item.body) {
          item.body.forEach((bodyItem: any) => {
            const { sliceType, __typename, ...rest } = bodyItem
            newBody.push({
              [lowercaseFirstCharacter(bodyItem.sliceType)]: {
                ...rest,
              },
            })
          })
        }
        item.body = newBody

        item.default = item.isDefault
        delete item.isDefault
        delete item.type
        delete item.updatedAt
        delete item.parentId
        delete item.__typename
      })

      const input = {
        checkinGroupDocumentItems: currentCheckInGroupCopy.document.items,
        parentId: currentCheckInGroupCopy.document.parentId,
        type: "CheckinGroup",
      }

      logger(Status.Api, "MUTATION documentUpsert", upsertDocumentCheckInGroup)
      const { data } = await upsertDocumentCheckInGroupMutation({
        variables: { input: input },
      })
      logger(
        Status.Info,
        `checkIn group ${data.documentUpsert.parentId} document upserted`,
        data.documentUpsert
      )

      setCurrentCheckInGroup((current) => {
        return {
          ...current,
          document: data.documentUpsert,
        }
      })
      setPreChangesCurrentCheckInGroup((current) => {
        return {
          ...current,
          document: deepCopy(data.documentUpsert),
        }
      })

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `documentUpsert`, e.message)
      }
      return false
    }
  }

  // create checkin group
  const createCheckInGroup = async (input: {
    startDate: string
    endDate: string
    teamId?: string
  }) => {
    try {
      logger(Status.Api, "MUTATION checkinGroupInsert", checkinGroupInsert)
      const { data } = await checkinGroupInsertMutation({
        variables: { input: input },
      })
      logger(Status.Info, `checkIn group created`, data.checkinGroupInsert)

      return data.checkinGroupInsert.id
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `checkinGroupInsert`, e.message)
      }
      return false
    }
  }

  // create checkin group document
  const createCheckInGroupDocument = async (input: {
    parentId: string
    type: string
    checkinGroupDocumentItems: {
      body: object[]
      default: boolean
      lang: string
      title: string
      description: string
    }[]
  }) => {
    try {
      logger(Status.Api, "MUTATION documentUpsert", upsertDocumentCheckInGroup)
      const { data } = await upsertDocumentCheckInGroupMutation({
        variables: { input: input },
      })
      logger(Status.Info, `document created`, data.documentUpsert)

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `documentUpsert`, e.message)
      }
      return false
    }
  }

  // delete checkin group
  const deleteCheckInGroup = async (id: string) => {
    try {
      logger(Status.Api, "MUTATION checkinGroupDelete", checkinGroupDelete)
      await checkinGroupDeleteMutation({
        variables: {
          input: {
            id,
          },
        },
      })
      logger(Status.Info, `checkIn group ${id} deleted`)

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `checkinGroupDelete`, e.message)
      }
      return false
    }
  }

  // add translation
  const addTranslation = (translationToAdd: string) => {
    const documentToAdd: CheckInGroupsDocument = {
      body: [],
      isDefault: false,
      lang: translationToAdd,
      title: "Title",
      description: "Description",
      type: "CheckinGroup",
    }

    translationsErrors.push({
      lang: translationToAdd,
      hasErrors: false,
    })
    setTranslationsErrors([...translationsErrors])

    currentCheckInGroup.document.items.push(documentToAdd)
    setCurrentCheckInGroup({ ...currentCheckInGroup })
  }

  // copy details from default translation
  const copyDetailsFromDefault = (itemToCopyToIndex: number) => {
    const defaultItem = currentCheckInGroup.document.items.filter(
      (item) => item.isDefault
    )[0]

    currentCheckInGroup.document.items[itemToCopyToIndex].title =
      defaultItem.title
    currentCheckInGroup.document.items[itemToCopyToIndex].description =
      defaultItem.description
    currentCheckInGroup.document.items[itemToCopyToIndex].body =
      defaultItem.body

    setCurrentCheckInGroup({ ...currentCheckInGroup })
  }

  // copy body from default translation
  const copyBodyFromDefault = (itemToCopyToIndex: number) => {
    const defaultItem = currentCheckInGroup.document.items.filter(
      (item) => item.isDefault
    )[0]

    currentCheckInGroup.document.items[itemToCopyToIndex].body =
      defaultItem.body

    setCurrentCheckInGroup({ ...currentCheckInGroup })
  }

  // check if user has done changes to details
  useEffect(() => {
    if (currentCheckInGroup && preChangesCurrentCheckInGroup) {
      const currentCheckInGroupCopy: CheckInGroup =
        deepCopy(currentCheckInGroup)
      const preChangesCurrentCheckInGroupCopy: CheckInGroup = deepCopy(
        preChangesCurrentCheckInGroup
      )

      if (
        JSON.stringify(currentCheckInGroupCopy) ===
        JSON.stringify(preChangesCurrentCheckInGroupCopy)
      ) {
        setDoneChanges(false)
      } else {
        setDoneChanges(true)
      }
    }
  }, [currentCheckInGroup, preChangesCurrentCheckInGroup])

  // cancel details changes
  const cancelChanges = () => {
    setCurrentCheckInGroup(deepCopy(preChangesCurrentCheckInGroup))
    resetErrors()
  }

  // add update function to panel status
  useEffect(() => {
    panelStatus.filter(
      (item) => item.name === "Check-in Groups"
    )[0].updateFunction = getCheckInGroupsList
  }, [])

  return (
    <CheckInGroupsContext.Provider
      value={{
        loading,
        setLoading,
        checkInGroupsList,
        getCheckInGroupsList,
        hasSearched,
        getCurrentCheckInGroup,
        currentCheckInGroup,
        setCurrentCheckInGroup,
        preChangesCurrentCheckInGroup,
        currentCheckInGroupCheckInsList,
        setCurrentCheckInGroupCheckInsList,
        doneChanges,
        cancelChanges,
        updatingList,
        setUpdatingList,
        checkInGroupsListNextToken,
        loadMoreCheckInGroups,
        editMode,
        setEditMode,
        addTranslation,
        hasError,
        translationsErrors,
        setTranslationsErrors,
        copyDetailsFromDefault,
        copyBodyFromDefault,
        createCheckInGroup,
        createCheckInGroupDocument,
        upsertCheckInGroupDocument,
        deleteCheckInGroup,
      }}
    >
      {children}
    </CheckInGroupsContext.Provider>
  )
}

export { CheckInGroupsController, CheckInGroupsContext }
