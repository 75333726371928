import {
  Chip,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material"
import { Dispatch, SetStateAction, useContext } from "react"
import { MainContext } from "../../controllers/main"
import { useNavigate } from "react-router-dom"
import TitleTableCell from "../global/titleTableCell"
import TranslationsStack from "../global/translationsStack"
import { calculateTimeElapsed } from "../../services/utilities/utility"
import CheckInGroup from "../../models/checkInGroup"
import { OpenInNewRounded } from "@mui/icons-material"

const CheckInGroupsListRow = ({
  item,
  selectedCheckInGroup,
  setSelectedCheckInGroup,
  forDialog,
}: {
  item: CheckInGroup
  selectedCheckInGroup?: CheckInGroup
  setSelectedCheckInGroup?: Dispatch<SetStateAction<CheckInGroup>>
  forDialog: boolean
}) => {
  const navigate = useNavigate()
  const { cmdPressed, setAnimation } = useContext(MainContext)

  return (
    <TableRow
      key={item.id}
      hover
      style={{
        cursor: "pointer",
        backgroundColor:
          selectedCheckInGroup && selectedCheckInGroup.id === item.id
            ? "lightgray"
            : null,
      }}
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
      }}
      onClick={() => {
        if (!forDialog) {
          if (cmdPressed) {
            window.open(`${window.location.href}/${item.id}`)
          } else {
            setAnimation(false)
            setTimeout(() => {
              navigate(`/checkingroups/${item.id}`)
            }, 250)
          }
        } else {
          setSelectedCheckInGroup(item)
        }
      }}
    >
      <TitleTableCell
        title={
          item.document.items.filter(
            (documentItem) => documentItem.isDefault
          )[0].title
        }
        id={item.id}
      />
      <TableCell>
        <TranslationsStack documentItems={item.document.items} />
      </TableCell>
      <TableCell>
        {new Date(item.startDate) > new Date() ? (
          <Chip size="small" label="Not started" color="default" />
        ) : new Date(item.endDate) >= new Date() ? (
          <Chip size="small" label="Current" color="success" />
        ) : (
          <Chip size="small" label="Ended" color="error" />
        )}
      </TableCell>
      <TableCell>
        <Stack gap={0.5} direction="row" alignItems="center">
          <Typography
            variant="caption"
            style={{
              whiteSpace: "nowrap",
              maxWidth: 180,
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {item.team && item.team.id !== "team_default"
              ? item.team.document.items.find((item) => item.isDefault).title
              : "Global (App)"}
          </Typography>
          {item.team && item.team.id !== "team_default" ? (
            <IconButton
              onClick={(e) => {
                e.stopPropagation()
                window.open(`/teams/${item.team.id}`)
              }}
            >
              <OpenInNewRounded style={{ fontSize: 14 }} />
            </IconButton>
          ) : null}
        </Stack>
      </TableCell>
      <TableCell style={{ minWidth: 120 }}>
        <Typography variant="caption">
          {calculateTimeElapsed(item.updatedAt)}
        </Typography>
      </TableCell>
    </TableRow>
  )
}

export default CheckInGroupsListRow
