import { Search } from "@mui/icons-material"
import { Button, InputAdornment, Stack, TextField } from "@mui/material"
import { useContext } from "react"
import { CheckInsContext } from "../../controllers/checkIns"

const CheckInsListTopBar = ({
  addButtonOnClick,
}: {
  addButtonOnClick?: () => void
}) => {
  const { loading } = useContext(CheckInsContext)

  return (
    <Stack
      style={{
        paddingTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 4,
      }}
      spacing={1}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <TextField
          disabled
          size="small"
          fullWidth
          id="outlined-basic"
          placeholder="Search Check-ins"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="contained"
          disabled={loading}
          style={{
            whiteSpace: "nowrap",
            minWidth: "max-content",
            height: 40,
          }}
          onClick={addButtonOnClick}
        >
          Add Check-in
        </Button>
      </Stack>
    </Stack>
  )
}

export default CheckInsListTopBar
