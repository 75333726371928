import {
  Button,
  CardContent,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { useContext, useState, useRef } from "react"
import html2canvas from "html2canvas"
import { MainContext } from "../../controllers/main"
import { CheckInsContext } from "../../controllers/checkIns"
import { CheckinDifficultyLevel, CheckInKind } from "../../services/config/enum"
import {
  capitalizeFirstCharacter,
  enumAsArray,
} from "../../services/utilities/utility"
import ISODatePicker from "../global/isoDatePicker"
import QRCode from "react-qr-code"
import { DownloadRounded, OpenInNewRounded } from "@mui/icons-material"

const CheckInGeneralEdit = () => {
  const { showPadding } = useContext(MainContext)
  const { currentCheckIn, setCurrentCheckIn, editMode } =
    useContext(CheckInsContext)

  // qr code download
  const [showQrDownloadButton, setShowQrDownloadButton] =
    useState<boolean>(false)
  const qrCodeRef = useRef<HTMLDivElement>(null)

  const handleDownloadQRCode = async () => {
    if (!qrCodeRef.current) return

    try {
      const canvas = await html2canvas(qrCodeRef.current, {
        backgroundColor: "white",
        scale: 5,
      })

      const link = document.createElement("a")
      link.download = `qr-code-${currentCheckIn.id}.png`
      link.href = canvas.toDataURL("image/png")
      link.click()
    } catch (error) {
      console.error("Error downloading QR code:", error)
    }
  }

  return (
    <CardContent
      style={{
        height:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        maxHeight:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        overflowY: "scroll",
      }}
    >
      <Stack spacing={2}>
        <Typography variant="h6" className="card-title">
          QR Code
        </Typography>
        <div
          style={{
            width: 192,
            height: 192,
            position: "relative",
            cursor: "pointer",
          }}
          onMouseEnter={() => setShowQrDownloadButton(true)}
          onMouseLeave={() => setShowQrDownloadButton(false)}
        >
          <div ref={qrCodeRef} style={{ width: "100%", height: "100%" }}>
            <QRCode
              value={`${process.env.REACT_APP_CHECKINS_QR_REDIRECT_URL}?id=${
                currentCheckIn.id
              }&checkinGroupId=${currentCheckIn.checkinGroup.id}${
                currentCheckIn.team && currentCheckIn.team.id !== "team_default"
                  ? `&teamId=${currentCheckIn.team.id}`
                  : ""
              }`}
              size={192}
            />
          </div>
          <div
            className="center"
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              top: 0,
              backgroundColor: "rgba(0, 0, 0, 0.2)",
              opacity: showQrDownloadButton ? 1 : 0,
              transition: "200ms",
            }}
          >
            <Button
              variant="contained"
              startIcon={<DownloadRounded />}
              onClick={handleDownloadQRCode}
            >
              Download
            </Button>
          </div>
        </div>
        <Typography variant="h6" className="card-title">
          Configuration
        </Typography>
        <Stack gap={2} direction="row">
          <FormControl fullWidth size="small" disabled={!editMode}>
            <InputLabel id="kind-select">Kind</InputLabel>
            <Select
              labelId="kind-select"
              label="Kind"
              value={currentCheckIn.kind}
              onChange={(e) => {
                setCurrentCheckIn((current) => {
                  return {
                    ...current,
                    kind: e.target.value as CheckInKind,
                  }
                })
              }}
              readOnly
            >
              {(
                Object.keys(CheckInKind) as Array<keyof typeof CheckInKind>
              ).map((key) => (
                <MenuItem key={key} value={key}>
                  {key === "QRCODE"
                    ? "QR Code"
                    : capitalizeFirstCharacter((key as string).toLowerCase())}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth size="small" disabled={!editMode}>
            <InputLabel id="difficulty-level">Difficulty level</InputLabel>
            <Select
              labelId="difficulty-level"
              label="Difficulty level"
              value={currentCheckIn.difficultyLevel}
              onChange={(e) => {
                setCurrentCheckIn((current) => {
                  return {
                    ...current,
                    difficultyLevel: e.target.value as CheckinDifficultyLevel,
                  }
                })
              }}
              readOnly
            >
              {enumAsArray(CheckinDifficultyLevel).map(
                (item: string, index) => (
                  <MenuItem key={index} value={item}>
                    {capitalizeFirstCharacter(item)}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
          <FormControl fullWidth size="small" disabled={!editMode}>
            <InputLabel id="repeatable">Repeatable</InputLabel>
            <Select
              labelId="repeatable"
              label="Repeatable"
              value={currentCheckIn.repeatable ? "yes" : "no"}
              onChange={(e) => {
                setCurrentCheckIn((current) => {
                  return {
                    ...current,
                    repeatable: e.target.value === "yes",
                  }
                })
              }}
              readOnly
            >
              <MenuItem value="yes">Yes</MenuItem>
              <MenuItem value="no">No</MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <Stack gap={2} direction="row">
          <ISODatePicker
            label="Start date"
            date={currentCheckIn.startDate}
            setDate={(newDate: string) => {
              setCurrentCheckIn((current) => {
                return {
                  ...current,
                  startDate: newDate.split("T")[0],
                }
              })
            }}
            hour={12}
            disabled={!editMode}
            readOnly
          />
          <ISODatePicker
            label="End date"
            date={currentCheckIn.endDate}
            setDate={(newDate: string) => {
              setCurrentCheckIn((current) => {
                return {
                  ...current,
                  endDate: newDate.split("T")[0],
                }
              })
            }}
            hour={12}
            disabled={!editMode}
            readOnly
          />
        </Stack>
        <Stack gap={2} direction="row">
          <TextField
            fullWidth
            label="Check-in Group"
            size="small"
            value={
              currentCheckIn.checkinGroup.document.items.find(
                (item) => item.isDefault
              ).title
            }
            disabled={!editMode}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <Button
                  sx={{ paddingInline: 3 }}
                  onClick={() =>
                    window.open(
                      `/checkingroups/${currentCheckIn.checkinGroup.id}`
                    )
                  }
                >
                  <OpenInNewRounded
                    style={{
                      fontSize: 15,
                    }}
                  />
                </Button>
              ),
            }}
          />
          <TextField
            fullWidth
            label="Team"
            size="small"
            value={
              currentCheckIn.team && currentCheckIn.team.id !== "team_default"
                ? currentCheckIn.team.document.items.find(
                    (item) => item.isDefault
                  ).title
                : "Global (App)"
            }
            disabled={!editMode}
            InputProps={{
              readOnly: true,
              endAdornment:
                currentCheckIn.team &&
                currentCheckIn.team.id !== "team_default" ? (
                  <Button
                    sx={{ paddingInline: 3 }}
                    onClick={() =>
                      window.open(`/teams/${currentCheckIn.team.id}`)
                    }
                  >
                    <OpenInNewRounded
                      style={{
                        fontSize: 15,
                      }}
                    />
                  </Button>
                ) : null,
            }}
          />
        </Stack>
        <Typography variant="h6" className="card-title">
          Geography
        </Typography>
        <Stack direction="row" gap={2}>
          <FormControl fullWidth size="small" disabled={!editMode}>
            <InputLabel id="geo-enabled">Location enabled</InputLabel>
            <Select
              labelId="geo-enabled"
              label="Location enabled"
              value={currentCheckIn.geoEnabled ? "yes" : "no"}
              readOnly
            >
              <MenuItem value="yes">Yes</MenuItem>
              <MenuItem value="no">No</MenuItem>
            </Select>
          </FormControl>
          {currentCheckIn.geoEnabled && (
            <TextField
              fullWidth
              size="small"
              label="Radius"
              value={currentCheckIn.geoRadius}
              type="number"
              InputProps={{
                readOnly: true,
                endAdornment: <InputAdornment position="end">m</InputAdornment>,
              }}
              disabled={!editMode}
            />
          )}
        </Stack>
        {currentCheckIn.geoEnabled && (
          <Stack gap={2}>
            <Typography variant="h6" className="card-title">
              Coordinates
            </Typography>
            {currentCheckIn.geoCoordinates.map((coordinates, index) => (
              <Stack key={index} direction="row" gap={2}>
                <TextField
                  fullWidth
                  size="small"
                  label="Latitude"
                  value={coordinates.lat}
                  type="number"
                  InputProps={{ readOnly: true }}
                  disabled={!editMode}
                />
                <TextField
                  fullWidth
                  size="small"
                  label="Longitude"
                  value={coordinates.long}
                  type="number"
                  InputProps={{ readOnly: true }}
                  disabled={!editMode}
                />
              </Stack>
            ))}
          </Stack>
        )}
      </Stack>
    </CardContent>
  )
}

export default CheckInGeneralEdit
