import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material"
import {
  CSSProperties,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react"
import { useDropzone } from "react-dropzone"
import { Textfit } from "react-textfit"
import "../../styles/imageDropzone.scss"
import {
  DialogTransition,
  blobToBase64,
  enumAsArray,
  resizeFile,
} from "../../services/utilities/utility"
import Cropper from "react-easy-crop"
import { getCroppedImg } from "../../services/utilities/canvasUtils"
import { LoadingButton } from "@mui/lab"
import { mediaConstraints } from "../../services/config/constants"

enum AspectRatios {
  Original = "Original",
  "16 / 9" = "16 / 9",
  "4 / 3" = "4 / 3",
  "1 / 1" = "1 / 1",
  "3 / 4" = "3 / 4",
  "9 / 16" = "9 / 16",
}

const ImageDropzone = ({
  title,
  droppedImage,
  setDroppedImage,
  height = "120px",
  disabled = false,
  disabledText,
  error,
  errorType,
  imageCategory,
  style,
}: {
  title?: string
  droppedImage: { name: string; size: number; dataUrl: string } | null
  setDroppedImage: Dispatch<
    SetStateAction<{ name: string; size: number; dataUrl: string } | null>
  >
  height?: string
  disabled?: boolean
  disabledText?: string
  error?: boolean
  errorType?: string
  imageCategory: string | null
  style?: CSSProperties
}) => {
  // cropper aspect ratio
  const [aspectRatio, setAspectRatio] = useState<number>(1 / 1)

  // resize dimensions
  const [resizeWidth, setResizeWidth] = useState<number>(0)
  const [resizeHeight, setResizeHeight] = useState<number>(0)

  // reset aspectRatio, resizeWidth and resizeHeight on imageCategory change
  useEffect(() => {
    setAspectRatio(
      imageCategory === "action" ||
        imageCategory === "actionGroup" ||
        imageCategory === "actionGroupBadge"
        ? 1 / 1
        : imageCategory === "background"
        ? mediaConstraints.background.width / mediaConstraints.background.height
        : imageCategory === "thumbnail"
        ? mediaConstraints.thumbnail.width / mediaConstraints.thumbnail.height
        : imageCategory === "decor"
        ? mediaConstraints.decor1.width / mediaConstraints.decor1.height
        : imageCategory === "challenge"
        ? mediaConstraints.challenge.width / mediaConstraints.challenge.height
        : imageCategory === "checkin"
        ? mediaConstraints.checkin.width / mediaConstraints.checkin.height
        : imageCategory === "other"
        ? 16 / 9
        : 1 / 1
    )
    setResizeWidth(
      imageCategory === "background"
        ? mediaConstraints.background.width
        : imageCategory === "thumbnail"
        ? mediaConstraints.thumbnail.width
        : imageCategory === "decor"
        ? mediaConstraints.decor1.width
        : imageCategory === "challenge"
        ? mediaConstraints.challenge.width
        : imageCategory === "checkin"
        ? mediaConstraints.checkin.width
        : 0
    )
    setResizeHeight(
      imageCategory === "background"
        ? mediaConstraints.background.height
        : imageCategory === "thumbnail"
        ? mediaConstraints.thumbnail.height
        : imageCategory === "decor"
        ? mediaConstraints.decor1.height
        : imageCategory === "challenge"
        ? mediaConstraints.challenge.height
        : imageCategory === "checkin"
        ? mediaConstraints.checkin.height
        : 0
    )
  }, [imageCategory])

  // decor type (used if imageCategory === decor)
  const [decorType, setDecorType] = useState<"square" | "wide">("square")

  // other aspect ratio (used if imageCategory === other)
  const [otherAspectRatio, setOtherAspectRatio] = useState<AspectRatios>(
    AspectRatios.Original
  )

  // read file dropped in the drop zone
  const readFile = (file: File) => {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.addEventListener("load", () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    })
  }

  // on image dropped
  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      let imageDataUrl = await readFile(acceptedFiles[0])

      if (
        imageCategory === "content" ||
        imageCategory === "decor" ||
        imageCategory === "background" ||
        imageCategory === "thumbnail" ||
        imageCategory === "challenge" ||
        imageCategory === "other" ||
        imageCategory === "checkin"
      ) {
        setPlainDroppedImage({
          name: acceptedFiles[0].name,
          size: acceptedFiles[0].size,
          dataUrl: imageDataUrl as string,
        })

        setCropAlertOpen(true)
      } else {
        setDroppedImage({
          name: acceptedFiles[0].name,
          size: acceptedFiles[0].size,
          dataUrl: imageDataUrl as string,
        })
      }
    },
    [imageCategory]
  )

  // crop alert
  const [plainDroppedImage, setPlainDroppedImage] = useState<{
    name: string
    size: number
    dataUrl: string
  } | null>(null) // dropped image without cropping
  const [cropAlertOpen, setCropAlertOpen] = useState<boolean>(false)

  // image crop and resize
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<{
    height: number
    width: number
    x: number
    y: number
  }>()
  const [imageProcessingLoading, setImageProcessingLoading] =
    useState<boolean>(false)

  const onCropComplete = async (
    croppedArea: { height: number; width: number; x: number; y: number },
    croppedAreaPixels: { height: number; width: number; x: number; y: number }
  ) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }

  const onDonePressed = async () => {
    setImageProcessingLoading(true)

    const croppedImage = await getCroppedImg(
      plainDroppedImage.dataUrl,
      croppedAreaPixels
    )

    const blob = await fetch(croppedImage).then((r) => r.blob())

    if (
      imageCategory === "content" ||
      imageCategory === "decor" ||
      imageCategory === "background" ||
      imageCategory === "thumbnail" ||
      imageCategory === "challenge"
    ) {
      const croppedAndResizedImageDataUrl = await resizeFile(
        blob,
        resizeWidth,
        resizeHeight,
        blob.type.slice(blob.type.indexOf("/") + 1)
      )

      setDroppedImage({
        name: plainDroppedImage.name,
        size: blob.size,
        dataUrl: croppedAndResizedImageDataUrl as string,
      })
    } else {
      const base64 = await blobToBase64(blob)

      setDroppedImage({
        name: plainDroppedImage.name,
        size: blob.size,
        dataUrl: base64 as string,
      })
    }

    setCropAlertOpen(false)
    setImageProcessingLoading(false)
  }

  // dropzone
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/*": [".png", ".jpg", ".jpeg", ".svg"],
    },
    maxFiles: 1,
  })

  // when image has been dropped and if imageCategory === other, calculate its aspect ratio and set it
  const [originalAspectRatio, setOriginalAspectRatio] = useState<number | null>(
    null
  )

  const getImageDimensions = (file: string) => {
    return new Promise((resolved) => {
      var i = new Image()
      i.onload = function () {
        resolved(i.width / i.height)
      }
      i.src = file
    })
  }

  useEffect(() => {
    const getDimensions = async () => {
      const dimensions = await getImageDimensions(plainDroppedImage.dataUrl)
      setAspectRatio(dimensions as number)
      setOriginalAspectRatio(dimensions as number)
    }

    if (
      imageCategory === "other" &&
      plainDroppedImage &&
      plainDroppedImage.dataUrl
    ) {
      getDimensions()
    }
  }, [plainDroppedImage])

  return disabled ? (
    <div
      className="image-dropzone-container-disabled"
      style={{
        borderRadius: 4,
        height: height,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        padding: 15,
        ...style,
      }}
    >
      <div
        style={{
          position: "absolute",
          top: -10,
          left: 8,
          color: "#9e9e9f",
          fontSize: 12,
          backgroundColor: "white",
          paddingLeft: 5,
          paddingRight: 5,
        }}
      >
        {title}
      </div>
      <p style={{ textAlign: "center" }}>
        {disabledText && disabledText.length
          ? disabledText
          : "Drag and drop an image here or click to select"}
      </p>
    </div>
  ) : (
    <>
      <div
        {...getRootProps()}
        className={
          error
            ? "image-dropzone-container-error-prismic"
            : "image-dropzone-container"
        }
        style={{
          borderRadius: 4,
          height: height,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          padding: 15,
          ...style,
        }}
      >
        <div
          style={{
            position: "absolute",
            top: -10,
            left: 8,
            color: error ? "#d3302f" : "rgba(0, 0, 0, 0.6)",
            fontSize: 12,
            backgroundColor: "white",
            paddingLeft: 5,
            paddingRight: 5,
          }}
        >
          {title}
        </div>
        <input {...getInputProps()} />
        {!droppedImage ? (
          isDragActive ? (
            <p style={{ textAlign: "center" }}>Drop the image here...</p>
          ) : (
            <p style={{ textAlign: "center" }}>
              Drag and drop an image here or click to select
            </p>
          )
        ) : isDragActive ? (
          <p style={{ textAlign: "center" }}>Drop the image here...</p>
        ) : (
          <Stack spacing={0}>
            <img
              src={droppedImage.dataUrl}
              style={{
                maxHeight: `calc(${height} - 40px)`,
                maxWidth: "100%",
                objectFit: "contain",
              }}
            />
            <p
              style={{
                textAlign: "center",
                color: error ? "#d3302f" : "black",
                fontSize: 10,
                marginBottom: -6,
              }}
            >
              {droppedImage.name.length > 50
                ? droppedImage.name.slice(0, 50) + "..."
                : droppedImage.name}{" "}
              |{" "}
              {droppedImage.size / 1000 > 999
                ? Math.round(
                    (droppedImage.size / 1000000 + Number.EPSILON) * 10
                  ) /
                    10 +
                  "mb"
                : Math.round(droppedImage.size / 1000 + Number.EPSILON) + "kb "}
            </p>
          </Stack>
        )}
        <Textfit
          style={{
            color: "#d3302f",
            position: "absolute",
            bottom: -25,
            left: 8,
            opacity: error ? 1 : 0,
            width: "calc(100% - 7px)",
            height: 20,
            overflow: "scroll",
          }}
          max={12}
          min={9}
          mode="single"
        >
          {errorType}
        </Textfit>
      </div>
      <Dialog open={cropAlertOpen} TransitionComponent={DialogTransition}>
        <DialogTitle>Crop image</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <div
              style={{
                width: 550,
                height: 300,
                position: "relative",
                borderRadius: 12,
                overflow: "hidden",
              }}
            >
              <Cropper
                image={plainDroppedImage ? plainDroppedImage.dataUrl : null}
                crop={crop}
                zoom={zoom}
                aspect={aspectRatio}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </div>
            {imageCategory === "decor" ? (
              <FormControl fullWidth size="small">
                <InputLabel id="decor-type-select">Decor type</InputLabel>
                <Select
                  labelId="decor-type-select"
                  label="Repeatable"
                  value={decorType}
                  onChange={(e) => {
                    setDecorType(e.target.value as "square" | "wide")
                    if (e.target.value === "square") {
                      setAspectRatio(
                        mediaConstraints.decor1.width /
                          mediaConstraints.decor1.height
                      )
                      setResizeWidth(mediaConstraints.decor1.width)
                      setResizeHeight(mediaConstraints.decor1.height)
                    } else {
                      setAspectRatio(
                        mediaConstraints.decor2.width /
                          mediaConstraints.decor2.height
                      )
                      setResizeWidth(mediaConstraints.decor2.width)
                      setResizeHeight(mediaConstraints.decor2.height)
                    }
                  }}
                >
                  <MenuItem value="square">Square</MenuItem>
                  <MenuItem value="wide">Wide</MenuItem>
                </Select>
              </FormControl>
            ) : imageCategory === "other" ? (
              <FormControl fullWidth size="small">
                <InputLabel id="aspect-ratio-select">Aspect ratio</InputLabel>
                <Select
                  labelId="aspect-ratio-select"
                  label="Aspect ratio"
                  value={otherAspectRatio}
                  onChange={(e) => {
                    const value = e.target.value
                    setOtherAspectRatio(value as AspectRatios)
                    if (value === "Original") {
                      setAspectRatio(originalAspectRatio)
                    } else {
                      setAspectRatio(
                        parseInt(value) /
                          parseInt(value.slice(value.indexOf("/") + 1))
                      )
                    }
                  }}
                >
                  {enumAsArray(AspectRatios).map((key: string) => (
                    <MenuItem key={key} value={key}>
                      {key}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : null}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setTimeout(() => {
                setPlainDroppedImage(null)
                setCrop({ x: 0, y: 0 })
                setZoom(1)
              }, 100)
              setCropAlertOpen(false)
            }}
            disabled={imageProcessingLoading}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            onClick={onDonePressed}
            loading={imageProcessingLoading}
          >
            Done
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ImageDropzone
