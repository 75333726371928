import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grow,
  IconButton,
  List,
  ListItem,
  Stack,
  Tab,
  Tabs,
} from "@mui/material"
import { useCallback, useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import EditContainer from "../../components/global/editContainer"
import EditHeading from "../../components/global/editHeading"
import PageSpinner from "../../components/global/pageSpinner"
import { MainContext } from "../../controllers/main"
import { LanguagesContext } from "../../controllers/languages"
import { AddCircleRounded } from "@mui/icons-material"
import LoadingBackdrop from "../../components/global/loadingBackdrop"
import CantPerformActionDialog from "../../components/global/cantPerformActionDialog"
import { CheckInGroupsContext } from "../../controllers/checkInGroups"
import CheckInGroupGeneralEdit from "../../components/checkInGroups/checkInGroupGeneralEdit"
import CheckInGroupTranslationsEdit from "../../components/checkInGroups/checkInGroupTranslationsEdit"

const CheckInGroupEdit = () => {
  const { animation, setAnimation, showPadding, setChangesSaved } =
    useContext(MainContext)
  const { languagesForEpisodeTabs } = useContext(LanguagesContext)
  const {
    loading: controllerLoading,
    setLoading: setControllerLoading,
    editMode,
    setEditMode,
    doneChanges,
    cancelChanges,
    currentCheckInGroup,
    getCurrentCheckInGroup,
    preChangesCurrentCheckInGroup,
    addTranslation,
    hasError,
    upsertCheckInGroupDocument,
    deleteCheckInGroup,
  } = useContext(CheckInGroupsContext)
  const { checkInGroupId } = useParams()
  const navigate = useNavigate()

  // prevent tab close
  useEffect(() => {
    const handleTabClose = (event: any) => {
      event.preventDefault()
      console.log("beforeunload event triggered")
      return (event.returnValue = "Are you sure you want to exit?")
    }

    window.addEventListener("beforeunload", handleTabClose)

    return () => {
      window.removeEventListener("beforeunload", handleTabClose)
    }
  }, [])

  // loading
  const [loading, setLoading] = useState<boolean>(true)

  // page error
  const [pageError, setPageError] = useState<boolean>(false)

  // fetch current checkin
  const fetchAll = async () => {
    const noErrors = await getCurrentCheckInGroup(checkInGroupId)
    if (noErrors) {
      setLoading(false)
      setControllerLoading(false)
    } else {
      setPageError(true)
    }
  }

  useEffect(() => {
    fetchAll()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // tabs
  const [selectedTab, setSelectedTab] = useState<number>(0)

  // add translation dialog
  const [addTranslationOpen, setAddTranslationOpen] = useState<boolean>(false)
  const [translationToAdd, setTranslationToAdd] = useState<
    { value: string; label: string; flag?: string } | undefined
  >()

  const handleAddTranslationClose = () => {
    setAddTranslationOpen(false)
    setTimeout(() => {
      setTranslationToAdd(undefined)
    }, 150)
  }

  // no default translation alert dialog
  const [
    noDefaultTranslationAlertDialogOpen,
    setNoDefaultTranslationAlertDialogOpen,
  ] = useState<boolean>(false)

  // delete checkIn dialog
  const [deleteCheckInDialogOpen, setDeleteCheckInDialogOpen] =
    useState<boolean>(false)

  const handleDeleteCheckInDialog = useCallback(() => {
    setDeleteCheckInDialogOpen((current) => !current)
  }, [])

  return loading ? (
    <PageSpinner
      showBackButton={pageError}
      backButtonOnClick={() => {
        setAnimation(false)
        navigate("/checkingroups")
      }}
    />
  ) : (
    <EditContainer>
      <EditHeading
        saveButtonLoading={controllerLoading}
        backButtonOnClick={() => {
          setAnimation(false)
          setTimeout(() => {
            navigate("/checkingroups")
          }, 300)
        }}
        title={
          preChangesCurrentCheckInGroup.document.items.filter(
            (documentItem) => documentItem.isDefault
          )[0].title
        }
        id={preChangesCurrentCheckInGroup.id}
        showButtons
        showEditButton
        editModeActive={editMode}
        setEditModeActive={setEditMode}
        saveButtonDisabled={!doneChanges || hasError}
        undoButtonDisabled={!doneChanges}
        doneChanges={doneChanges}
        undoButtonOnClick={() => {
          if (
            selectedTab > preChangesCurrentCheckInGroup.document.items.length
          ) {
            setSelectedTab(preChangesCurrentCheckInGroup.document.items.length)
          }
          cancelChanges()
        }}
        saveButtonOnClick={async () => {
          if (
            currentCheckInGroup.document.items.some((item) => item.isDefault)
          ) {
            setControllerLoading(true)

            const noErrors = await upsertCheckInGroupDocument()
            if (noErrors) {
              setControllerLoading(false)
              setChangesSaved(true)
            } else {
              setControllerLoading(false)
            }
          } else {
            setNoDefaultTranslationAlertDialogOpen(true)
          }
        }}
        showDeleteButton
        deleteButtonOnClick={handleDeleteCheckInDialog}
      />
      <Grow
        in={animation}
        timeout={300}
        style={{
          margin: showPadding === "yes" ? 16 : 0,
          marginTop: 0,
        }}
      >
        <Stack>
          {/* languages tabs */}
          <Stack direction="row" justifyContent="flex-start">
            <Card
              variant="outlined"
              style={{
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                borderTopLeftRadius: showPadding === "yes" ? null : 0,
                borderBottom: "none",
                borderLeft: showPadding === "yes" ? null : "none",
              }}
            >
              <Stack direction="row" justifyContent="flex-start">
                <Tabs
                  value={selectedTab}
                  onChange={(e: any, newValue: number) => {
                    setSelectedTab(newValue)
                  }}
                  aria-label="languages tabs"
                >
                  <Tab label="General" style={{ minWidth: 180 }} />
                  {currentCheckInGroup.document.items.map((item) => (
                    <Tab
                      key={item.lang}
                      style={{ minWidth: 180 }}
                      label={
                        <Stack direction="row" spacing={1} alignItems="center">
                          <span>
                            {
                              languagesForEpisodeTabs.filter(
                                (arrayItem) => arrayItem.value === item.lang
                              )[0].flag
                            }
                          </span>
                          {currentCheckInGroup.document.items.filter(
                            (documentItem) => documentItem.lang === item.lang
                          )[0].isDefault ? (
                            <Stack
                              direction="row"
                              spacing={0.5}
                              alignItems="center"
                            >
                              <span>
                                {
                                  languagesForEpisodeTabs.filter(
                                    (arrayItem) => arrayItem.value === item.lang
                                  )[0].label
                                }
                              </span>
                              <span style={{ fontSize: 10, marginTop: 2 }}>
                                (Default)
                              </span>
                            </Stack>
                          ) : (
                            <span>
                              {
                                languagesForEpisodeTabs.filter(
                                  (arrayItem) => arrayItem.value === item.lang
                                )[0].label
                              }
                            </span>
                          )}
                        </Stack>
                      }
                    />
                  ))}
                </Tabs>
                <Divider orientation="vertical" variant="middle" flexItem />
                <div
                  style={{
                    minWidth: 70,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <IconButton
                    disabled={!editMode}
                    aria-label="add language button"
                    component="span"
                    style={{ width: 48, height: 48 }}
                    onClick={() => {
                      setAddTranslationOpen(true)
                    }}
                  >
                    <AddCircleRounded />
                  </IconButton>
                </div>
              </Stack>
            </Card>
          </Stack>
          <Card style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
            {!selectedTab ? (
              <CheckInGroupGeneralEdit />
            ) : (
              <CheckInGroupTranslationsEdit itemIndex={selectedTab - 1} />
            )}
          </Card>
        </Stack>
      </Grow>
      <LoadingBackdrop open={controllerLoading} />
      {/* add translation dialog */}
      <Dialog onClose={handleAddTranslationClose} open={addTranslationOpen}>
        <DialogTitle>Add translation</DialogTitle>
        {translationToAdd ? (
          <DialogContent>
            <DialogContentText id="alert-dialog-description-add-translation">
              Are you sure you want to add{" "}
              <b>{translationToAdd.label.toUpperCase()}</b>?
            </DialogContentText>
          </DialogContent>
        ) : languagesForEpisodeTabs.filter((item) => {
            if (
              item.value !== "general" &&
              !currentCheckInGroup.document.items.filter(
                (translationItem) => translationItem.lang === item.value
              )[0]
            ) {
              return item
            }
          }).length ? (
          <List sx={{ pt: 0, minWidth: 300 }}>
            {languagesForEpisodeTabs
              .filter((item) => {
                if (
                  item.value !== "general" &&
                  !currentCheckInGroup.document.items.filter(
                    (translationItem) => translationItem.lang === item.value
                  )[0]
                ) {
                  return item
                }
              })
              .map((item) => (
                <ListItem
                  button
                  onClick={() => {
                    setTranslationToAdd(item)
                  }}
                  key={item.value}
                >
                  <Stack direction="row" spacing={1}>
                    <span>{item.flag}</span>
                    <span>{item.label.toUpperCase()}</span>
                  </Stack>
                </ListItem>
              ))}
          </List>
        ) : (
          <DialogContent>
            <DialogContentText id="alert-dialog-description-add-translation">
              You have already added all languages available
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleAddTranslationClose}>Cancel</Button>
          {translationToAdd && (
            <Button
              onClick={() => {
                addTranslation(translationToAdd.value)
                handleAddTranslationClose()
                setSelectedTab(currentCheckInGroup.document.items.length)
              }}
            >
              Add {translationToAdd.label}
            </Button>
          )}
        </DialogActions>
      </Dialog>
      {/* no default translation alert dialog */}
      <CantPerformActionDialog
        open={noDefaultTranslationAlertDialogOpen}
        setOpen={setNoDefaultTranslationAlertDialogOpen}
        content="There must be a default translation"
      />
      {/* delete checkIn dialog */}
      <Dialog
        onClose={handleDeleteCheckInDialog}
        open={deleteCheckInDialogOpen}
      >
        <DialogTitle>Delete Check-in Group</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-delete-challenge">
            Are you sure you want to delete this Check-in Group? The operation
            is irreversible
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={controllerLoading}
            onClick={handleDeleteCheckInDialog}
          >
            Cancel
          </Button>
          <Button
            disabled={controllerLoading}
            color="error"
            onClick={async () => {
              setControllerLoading(true)

              const noErrors = await deleteCheckInGroup(currentCheckInGroup.id)

              if (noErrors) {
                setTimeout(() => {
                  setControllerLoading(false)
                  navigate("/checkingroups", {
                    state: { checkInGroupDeleted: true },
                  })
                }, 1000)
              } else {
                handleDeleteCheckInDialog()
              }
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </EditContainer>
  )
}

export default CheckInGroupEdit
