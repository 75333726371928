import { DatePicker } from "@mui/x-date-pickers"
import { Dispatch, SetStateAction } from "react"
import { DateTime } from "luxon"
import "../../styles/isoDatePicker.scss"

const ISODatePicker = ({
  label,
  date,
  setDate,
  error,
  errorLabel = "Invalid date",
  disabled = false,
  minDate,
  hour = 0,
  minute = 0,
  second = 0,
  helperText,
  disablePast = false,
  withHelperText = true,
  utc = true,
  readOnly,
}: {
  label: string
  date: string
  setDate: Dispatch<SetStateAction<string>> | ((newValue: string) => void)
  error?: boolean
  errorLabel?: string
  disabled?: boolean
  minDate?: string
  hour?: number
  minute?: number
  second?: number
  helperText?: string
  disablePast?: boolean
  withHelperText?: boolean
  utc?: boolean
  readOnly?: boolean
}) => {
  return (
    <DatePicker
      label={label}
      format="dd/MM/yyyy"
      value={DateTime.fromISO(date)}
      onChange={(newValue) => {
        setDate(
          newValue
            ? utc
              ? newValue
                  .set({ hour: hour, minute: minute, second: second })
                  .toUTC()
                  .toISO()
              : newValue
                  .set({ hour: hour, minute: minute, second: second })
                  .toISO()
            : ""
        )
      }}
      // onError={() => {
      //   if (setError) {
      //     setError((current) => !current)
      //   }
      // }}
      slotProps={{
        textField: {
          error: error ? true : false,
          helperText: error && withHelperText ? errorLabel : helperText,
        },
      }}
      disabled={disabled}
      minDate={minDate ? DateTime.fromISO(minDate) : null}
      className="date-picker"
      disablePast={disablePast}
      readOnly={readOnly}
    />
  )
}

export default ISODatePicker
