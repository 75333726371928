import { LoadingButton } from "@mui/lab"
import {
  Button,
  CardActions,
  CardContent,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  Paper,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  Dialog,
  MenuItem,
  Select,
  InputAdornment,
} from "@mui/material"
import {
  Dispatch,
  MouseEventHandler,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import {
  capitalizeFirstCharacter,
  DialogTransition,
  enumAsArray,
  isValidURL,
  lowercaseFirstCharacter,
  stripHtml,
  testYtURL,
} from "../../services/utilities/utility"
import DialogTopBar from "../global/dialogTopBar"
import LoadingBackdrop from "../global/loadingBackdrop"
import { useNavigate } from "react-router-dom"
import { MainContext } from "../../controllers/main"
import {
  BodySliceType,
  CheckinDifficultyLevel,
  CheckInKind,
} from "../../services/config/enum"
import { LanguagesContext } from "../../controllers/languages"
import EmbedSlice from "../../models/bodySlices/embedSlice"
import ImageSlice from "../../models/bodySlices/imageSlice"
import QuoteSlice from "../../models/bodySlices/quoteSlice"
import TextSlice from "../../models/bodySlices/textSlice"
import { CheckInsContext } from "../../controllers/checkIns"
import BlockEditor from "../blockEditor/blockEditor"
import Team from "../../models/team"
import ChangeTeamDialog from "../teams/changeTeamDialog"
import ISODatePicker from "../global/isoDatePicker"
import { AddCircleRounded, DeleteRounded } from "@mui/icons-material"
import CheckInGroup from "../../models/checkInGroup"
import ChangeCheckInGroupDialog from "../checkInGroups/changeCheckInGroupDialog"
import CheckIn from "../../models/checkIn"

const CreateCheckInDialog = ({
  dialogOpen,
  setDialogOpen,
  handleDialogChange,
  topBarText,
  preSelectedCheckInGroup,
  setCurrentCheckInGroupCheckInsList,
}: {
  dialogOpen: boolean
  setDialogOpen: Dispatch<SetStateAction<boolean>>
  handleDialogChange: MouseEventHandler<HTMLButtonElement>
  topBarText: string
  preSelectedCheckInGroup?: CheckInGroup
  setCurrentCheckInGroupCheckInsList?: Dispatch<SetStateAction<CheckIn[]>>
}) => {
  const { setAnimation } = useContext(MainContext)
  const { loading, setLoading, createCheckIn, createCheckInDocument } =
    useContext(CheckInsContext)
  const { languages, languagesForEpisodeTabs } = useContext(LanguagesContext)
  const navigate = useNavigate()

  // prevent tab close
  const handleTabClose = useCallback((event: any) => {
    event.preventDefault()
    console.log("beforeunload event triggered")
    return (event.returnValue = "Are you sure you want to exit?")
  }, [])

  useEffect(() => {
    if (dialogOpen) {
      window.addEventListener("beforeunload", handleTabClose)
    } else {
      window.removeEventListener("beforeunload", handleTabClose)
    }
  }, [dialogOpen])

  // configuration
  const [kind, setKind] = useState<CheckInKind>(CheckInKind.QRCODE)
  const [difficultyLevel, setDifficultyLevel] =
    useState<CheckinDifficultyLevel>(CheckinDifficultyLevel.easy)
  const [repeatable, setRepeatable] = useState<boolean>(false)
  const [team, setTeam] = useState<Team | null>(null)
  const [startDate, setStartDate] = useState<string>("")
  const [endDate, setEndDate] = useState<string>("")
  const [checkInGroup, setCheckInGroup] = useState<CheckInGroup | null>(
    preSelectedCheckInGroup ?? null
  )

  // geography
  const [geoEnabled, setGeoEnabled] = useState<boolean>(false)
  const [geoRadius, setGeoRadius] = useState<string>("1")
  const [geoCoordinates, setGeoCoordinates] = useState<
    {
      lat: string
      long: string
    }[]
  >([])

  // document
  const [defaultTranslation, setDefaultTranslation] = useState<string>("")
  const [title, setTitle] = useState<string>("")
  const [description, setDescription] = useState<string>("")
  const [zone, setZone] = useState<string>("")
  const [body, setBody] = useState<
    (EmbedSlice | ImageSlice | QuoteSlice | TextSlice)[]
  >([])

  // check errors in body
  const [bodyError, setBodyError] = useState<boolean>(false)

  useEffect(() => {
    let errorInBody = false
    body.forEach((bodyItem) => {
      if (
        (bodyItem.sliceType === BodySliceType.BodyTextSlice &&
          !stripHtml(bodyItem.text).length) ||
        (bodyItem.sliceType === BodySliceType.BodyQuoteSlice &&
          !bodyItem.quoteText.length) ||
        (bodyItem.sliceType === BodySliceType.BodyImageSlice &&
          (!bodyItem.imgUrl.length || !isValidURL(bodyItem.imgUrl))) ||
        (bodyItem.sliceType === BodySliceType.BodyEmbedSlice &&
          (!bodyItem.embedUrl.length || !testYtURL(bodyItem.embedUrl)))
      ) {
        errorInBody = true
      }
    })
    if (errorInBody) {
      setBodyError(true)
    } else {
      setBodyError(false)
    }
  }, [body])

  // current slide and scrolling when changes
  const [currentSlide, setCurrentSlide] = useState<number>(0)

  useEffect(() => {
    setTimeout(() => {
      let slide = document.getElementById(currentSlide.toString())
      slide?.scrollIntoView({ behavior: "smooth" })
    }, 10)
  }, [currentSlide])

  // slides
  const slides = [
    {
      label: "Configuration",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Configuration
          </Typography>
          <Stack direction="row" gap={2}>
            <FormControl fullWidth size="small">
              <InputLabel id="kind-select">Kind</InputLabel>
              <Select
                labelId="kind-select"
                label="Kind"
                value={kind}
                onChange={(e) => {
                  setKind(e.target.value as CheckInKind)
                }}
              >
                {(
                  Object.keys(CheckInKind) as Array<keyof typeof CheckInKind>
                ).map((key) => (
                  <MenuItem key={key} value={key}>
                    {key === "QRCODE"
                      ? "QR Code"
                      : capitalizeFirstCharacter((key as string).toLowerCase())}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth size="small">
              <InputLabel id="difficulty-level">Difficulty level</InputLabel>
              <Select
                labelId="difficulty-level"
                label="Difficulty level"
                value={difficultyLevel}
                onChange={(e) => {
                  setDifficultyLevel(e.target.value as CheckinDifficultyLevel)
                }}
              >
                {enumAsArray(CheckinDifficultyLevel).map(
                  (item: string, index) => (
                    <MenuItem key={index} value={item}>
                      {capitalizeFirstCharacter(item)}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Stack>
          <Stack direction="row" gap={2}>
            <ISODatePicker
              label="Start date"
              date={startDate}
              setDate={(newDate: string) => {
                setStartDate(newDate)
              }}
              hour={12}
            />
            <ISODatePicker
              label="End date"
              date={endDate}
              setDate={(newDate: string) => {
                setEndDate(newDate)
              }}
              hour={12}
            />
          </Stack>
          <Stack gap={2} direction="row">
            <TextField
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <Stack direction="row" gap={1}>
                    <Button
                      sx={{ paddingInline: 3 }}
                      onClick={() => {
                        setChangeTeamDialogOpen(true)
                      }}
                    >
                      Change
                    </Button>
                    {team ? (
                      <Button
                        sx={{ paddingInline: 3 }}
                        onClick={() => {
                          setTeam(null)
                        }}
                      >
                        Remove
                      </Button>
                    ) : null}
                  </Stack>
                ),
              }}
              fullWidth
              label="Team"
              size="small"
              value={team ? team.name : "Global (App)"}
            />
            <FormControl fullWidth size="small">
              <InputLabel id="repeatable">Repeatable</InputLabel>
              <Select
                labelId="repeatable"
                label="Repeatable"
                value={repeatable ? "yes" : "no"}
                onChange={(e) => {
                  setRepeatable(e.target.value === "yes")
                }}
              >
                <MenuItem value="yes">Yes</MenuItem>
                <MenuItem value="no">No</MenuItem>
              </Select>
            </FormControl>
          </Stack>
          <TextField
            InputProps={{
              readOnly: true,
              endAdornment: preSelectedCheckInGroup ? null : (
                <Stack direction="row" gap={1}>
                  <Button
                    sx={{ paddingInline: 3 }}
                    onClick={() => {
                      setChangeCheckInGroupDialogOpen(true)
                    }}
                  >
                    {checkInGroup ? "Change" : "Select"}
                  </Button>
                  {checkInGroup ? (
                    <Button
                      sx={{ paddingInline: 3 }}
                      onClick={() => {
                        setCheckInGroup(null)
                      }}
                    >
                      Remove
                    </Button>
                  ) : null}
                </Stack>
              ),
            }}
            fullWidth
            label="Check-in Group"
            size="small"
            value={
              checkInGroup
                ? checkInGroup.document.items.find((item) => item.isDefault)
                    .title
                : ""
            }
            helperText={
              startDate &&
              endDate &&
              checkInGroup &&
              (new Date(startDate.split("T")[0]) <
                new Date(checkInGroup.startDate) ||
                new Date(endDate.split("T")[0]) >
                  new Date(checkInGroup.endDate))
                ? "Invalid Check-in Group for selected dates"
                : ""
            }
            error={
              startDate &&
              endDate &&
              checkInGroup &&
              (new Date(startDate.split("T")[0]) <
                new Date(checkInGroup.startDate) ||
                new Date(endDate.split("T")[0]) >
                  new Date(checkInGroup.endDate))
            }
          />
        </Stack>
      ),
    },
    {
      label: "Geography",
      component: (
        <Stack spacing={2} style={{ maxHeight: 295, overflowY: "auto" }}>
          <Typography variant="h6" className="card-title">
            Geography
          </Typography>
          <Stack direction="row" gap={2}>
            <FormControl fullWidth size="small">
              <InputLabel id="geo-enabled">Location enabled</InputLabel>
              <Select
                labelId="geo-enabled"
                label="Location enabled"
                value={geoEnabled ? "yes" : "no"}
                onChange={(e) => {
                  setGeoEnabled(e.target.value === "yes")

                  if (e.target.value === "yes") {
                    setGeoRadius("1")
                    setGeoCoordinates([
                      {
                        lat: "1",
                        long: "1",
                      },
                    ])
                  }
                }}
              >
                <MenuItem value="yes">Yes</MenuItem>
                <MenuItem value="no">No</MenuItem>
              </Select>
            </FormControl>
            {geoEnabled && (
              <TextField
                fullWidth
                size="small"
                label="Radius"
                value={geoRadius}
                onChange={(e) => {
                  setGeoRadius(e.target.value)
                }}
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">m</InputAdornment>
                  ),
                }}
              />
            )}
          </Stack>
          {geoEnabled && (
            <Stack gap={2}>
              <Typography variant="h6" className="card-title">
                Coordinates
              </Typography>
              {geoCoordinates.map((coordinates, index) => (
                <Stack key={index} direction="row" gap={2}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Latitude"
                    value={coordinates.lat}
                    onChange={(e) =>
                      setGeoCoordinates((current) => [
                        ...current.slice(0, index),
                        {
                          lat: e.target.value,
                          long: current[index].long,
                        },
                        ...current.slice(index + 1),
                      ])
                    }
                    type="number"
                    error={
                      !geoCoordinates[index].lat ||
                      parseFloat(geoCoordinates[index].lat) < -90 ||
                      parseFloat(geoCoordinates[index].lat) > 90
                    }
                    helperText={
                      !geoCoordinates[index].lat
                        ? "This field cannot be empty"
                        : parseFloat(geoCoordinates[index].lat) < -90 ||
                          parseFloat(geoCoordinates[index].lat) > 90
                        ? "Latitude must be between -90 and 90"
                        : ""
                    }
                  />
                  <TextField
                    fullWidth
                    size="small"
                    label="Longitude"
                    value={coordinates.long}
                    onChange={(e) =>
                      setGeoCoordinates((current) => [
                        ...current.slice(0, index),
                        {
                          lat: current[index].lat,
                          long: e.target.value,
                        },
                        ...current.slice(index + 1),
                      ])
                    }
                    type="number"
                    error={
                      !geoCoordinates[index].long ||
                      parseFloat(geoCoordinates[index].long) < -180 ||
                      parseFloat(geoCoordinates[index].long) > 180
                    }
                    helperText={
                      !geoCoordinates[index].long
                        ? "This field cannot be empty"
                        : parseFloat(geoCoordinates[index].long) < -180 ||
                          parseFloat(geoCoordinates[index].long) > 180
                        ? "Longitude must be between -180 and 180"
                        : ""
                    }
                  />
                  <Button
                    onClick={() =>
                      setGeoCoordinates((current) => [
                        ...current.slice(0, index),
                        ...current.slice(index + 1),
                      ])
                    }
                    style={{ maxHeight: 40 }}
                  >
                    <DeleteRounded color="error" />
                  </Button>
                </Stack>
              ))}
              <Button
                variant="outlined"
                startIcon={<AddCircleRounded />}
                onClick={() =>
                  setGeoCoordinates((current) => [
                    ...current,
                    { lat: "1", long: "1" },
                  ])
                }
              >
                Add coordinates
              </Button>
            </Stack>
          )}
        </Stack>
      ),
    },
    {
      label: "Language",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Default language
          </Typography>
          <FormControl fullWidth size="small">
            <InputLabel id="language-select">Default language</InputLabel>
            <Select
              labelId="language-select"
              label="Default language"
              value={defaultTranslation}
              onChange={(e) => {
                setDefaultTranslation(e.target.value)
              }}
            >
              {languages.map((lang) => (
                <MenuItem key={lang} value={lang}>
                  {lang.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      ),
    },
    {
      label: "Details",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Details in{" "}
            {defaultTranslation.length
              ? languagesForEpisodeTabs.filter(
                  (item) => item.value === defaultTranslation
                )[0].label
              : ""}
          </Typography>
          <TextField
            size="small"
            label="Title"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value)
            }}
            onBlur={() => {
              if (title) {
                setTitle((current) => current.trim())
              }
            }}
          />
          <TextField
            size="small"
            label="Description"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value)
            }}
            onBlur={() => {
              if (description) {
                setDescription((current) => current.trim())
              }
            }}
          />
          <TextField
            size="small"
            label="Zone"
            value={zone}
            onChange={(e) => {
              setZone(e.target.value)
            }}
            onBlur={() => {
              if (zone) {
                setZone((current) => current.trim())
              }
            }}
          />
        </Stack>
      ),
    },
    {
      label: "Body",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Body in{" "}
            {defaultTranslation.length
              ? languagesForEpisodeTabs.filter(
                  (item) => item.value === defaultTranslation
                )[0].label
              : ""}
          </Typography>
          <div
            style={{
              height: 260,
              maxHeight: 260,
              overflowY: "scroll",
            }}
          >
            <BlockEditor
              body={body}
              setBody={(
                body: (EmbedSlice | ImageSlice | QuoteSlice | TextSlice)[]
              ) => {
                setBody([...body])
              }}
            />
          </div>
        </Stack>
      ),
    },
  ]

  // reset dialog on close
  useEffect(() => {
    if (!dialogOpen) {
      setTimeout(() => {
        setKind(CheckInKind.QRCODE)
        setDifficultyLevel(CheckinDifficultyLevel.easy)
        setRepeatable(false)
        setTeam(null)
        setCheckInGroup(preSelectedCheckInGroup ?? null)
        setStartDate("")
        setEndDate("")
        setGeoEnabled(false)
        setGeoRadius("1")
        setGeoCoordinates([])
        setDefaultTranslation("")
        setTitle("")
        setDescription("")
        setZone("")
        setBody([])
        setCurrentSlide(0)
      }, 100)
    }
  }, [dialogOpen])

  // discard dialog
  const [discardDialogOpen, setDiscardDialogOpen] = useState<boolean>(false)

  const handleDiscardDialogCancel = () => {
    setDiscardDialogOpen(false)
  }

  const handleDiscardDialogClose = (e: any) => {
    setDiscardDialogOpen(false)
    handleDialogChange(e)
  }

  // should show discard dialog
  const [showDiscardDialog, setShowDiscardDialog] = useState<boolean>(false)

  useEffect(() => {
    if (startDate || endDate) {
      setShowDiscardDialog(true)
    } else {
      setShowDiscardDialog(false)
    }
  }, [startDate, endDate])

  // change team dialog
  const [changeTeamDialogOpen, setChangeTeamDialogOpen] =
    useState<boolean>(false)
  const [selectedTeam, setSelectedTeam] = useState<Team | null>(null)

  // change checkin group
  const [changeCheckInGroupDialogOpen, setChangeCheckInGroupDialogOpen] =
    useState<boolean>(false)
  const [selectedCheckInGroup, setSelectedCheckInGroup] =
    useState<CheckInGroup | null>(null)

  // create checkin
  const createNewCheckIn = async () => {
    setLoading(true)

    const input: {
      checkinGroupId: string
      kind: CheckInKind
      checkinDifficultyLevel: CheckinDifficultyLevel
      repeatable: boolean
      teamId?: string
      startDate: string
      endDate: string
      geoEnabled?: boolean
      geoRadius?: number
      geoCoordinates?: { lat: number; long: number }[]
    } = {
      checkinGroupId: checkInGroup.id,
      kind: kind,
      checkinDifficultyLevel: difficultyLevel,
      repeatable: repeatable,
      ...(team ? { teamId: team.id } : {}),
      startDate: startDate.split("T")[0],
      endDate: endDate.split("T")[0],
      ...(geoEnabled ? { geoEnabled } : {}),
      ...(geoEnabled ? { geoRadius: parseFloat(geoRadius) } : {}),
      ...(geoEnabled
        ? {
            geoCoordinates: geoCoordinates.map((item) => {
              return {
                lat: parseFloat(item.lat),
                long: parseFloat(item.long),
              }
            }),
          }
        : {}),
    }

    const result = await createCheckIn(input)

    if (typeof result !== "boolean") {
      const newCheckIn = result

      const newBody = []
      body.forEach((bodyItem: any) => {
        const { sliceType, ...rest } = bodyItem
        newBody.push({
          [lowercaseFirstCharacter(bodyItem.sliceType)]: {
            ...rest,
          },
        })
      })

      const documentInput: {
        parentId: string
        type: string
        checkinDocumentItems: {
          body: object[]
          default: boolean
          lang: string
          title: string
          description: string
          zone: string
        }[]
      } = {
        parentId: newCheckIn.id,
        type: "Checkin",
        checkinDocumentItems: [
          {
            body: newBody,
            default: true,
            lang: defaultTranslation,
            title,
            description,
            zone,
          },
        ],
      }

      const documentResult = await createCheckInDocument(documentInput)

      if (typeof documentResult !== "boolean") {
        if (preSelectedCheckInGroup) {
          newCheckIn.document = documentResult
          setCurrentCheckInGroupCheckInsList((current) => [
            ...current,
            newCheckIn,
          ])
          setDialogOpen(false)
        } else {
          setDialogOpen(false)
          setAnimation(false)
          setTimeout(() => {
            navigate(`/checkins/${newCheckIn.id}`)
          }, 250)
        }
      } else {
        setLoading(false)
      }
    } else {
      setLoading(false)
    }
  }

  return (
    <Dialog
      fullScreen
      open={dialogOpen}
      onClose={
        showDiscardDialog
          ? () => {
              setDiscardDialogOpen(true)
            }
          : handleDialogChange
      }
      TransitionComponent={DialogTransition}
    >
      <DialogTopBar
        handleDialogChange={
          showDiscardDialog
            ? () => {
                setDiscardDialogOpen(true)
              }
            : handleDialogChange
        }
        topBarText={topBarText}
      />
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          variant="outlined"
          style={{
            width: "70%",
            minWidth: 345,
            maxWidth: 850,
            height: 500,
            position: "relative",
          }}
        >
          <Typography
            variant="h4"
            textAlign="center"
            style={{ paddingTop: 16 }}
          >
            {topBarText}
          </Typography>
          <Stepper
            activeStep={currentSlide}
            style={{
              paddingTop: 24,
              paddingLeft: 8,
              paddingRight: 8,
              paddingBottom: 8,
              maxWidth: "100%",
              overflow: "overlay",
            }}
          >
            {slides.map((slide) => {
              return (
                <Step key={slide.label}>
                  <StepLabel>{slide.label}</StepLabel>
                </Step>
              )
            })}
          </Stepper>
          <div
            className="horizontal-scroll"
            style={{
              minWidth: 345,
              maxWidth: 850,
              height: "auto",
              display: "flex",
              flexDirection: "row",
              overflow: "hidden",
              scrollSnapType: "x mandatory",
            }}
          >
            {slides.map((slide, index) => (
              <div
                key={index}
                id={index.toString()}
                style={{ minWidth: "100%", scrollSnapAlign: "center" }}
              >
                <CardContent>{slide.component}</CardContent>
              </div>
            ))}
          </div>
          <CardActions
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              padding: 16,
              width: "100%",
            }}
          >
            <div style={{ width: "50%" }}>
              <Button
                variant="outlined"
                onMouseDown={(e: any) => {
                  if (!slides[currentSlide - 1]) {
                    if (showDiscardDialog) {
                      setDiscardDialogOpen(true)
                    } else {
                      handleDialogChange(e)
                    }
                  } else {
                    setCurrentSlide(currentSlide - 1)
                  }
                }}
              >
                {!slides[currentSlide - 1] ? "Close" : "Previous"}
              </Button>
            </div>
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <LoadingButton
                variant="contained"
                disabled={
                  (currentSlide === 0 &&
                    (!startDate ||
                      !endDate ||
                      !checkInGroup ||
                      new Date(endDate).getTime() <=
                        new Date(startDate).getTime())) ||
                  (currentSlide === 0 &&
                    checkInGroup &&
                    startDate &&
                    endDate &&
                    (new Date(startDate.split("T")[0]) <
                      new Date(checkInGroup.startDate) ||
                      new Date(endDate.split("T")[0]) >
                        new Date(checkInGroup.endDate))) ||
                  (currentSlide === 1 &&
                    geoEnabled &&
                    (!geoRadius || parseFloat(geoRadius) <= 0)) ||
                  (currentSlide === 1 &&
                    geoEnabled &&
                    (!geoCoordinates.length ||
                      geoCoordinates.some(
                        (item) =>
                          !item.lat ||
                          !item.long ||
                          parseFloat(item.lat) < -90 ||
                          parseFloat(item.lat) > 90 ||
                          parseFloat(item.long) < -180 ||
                          parseFloat(item.long) > 180
                      ))) ||
                  (currentSlide === 2 && !defaultTranslation) ||
                  (currentSlide === 3 && (!title || !description || !zone)) ||
                  (currentSlide === 4 && bodyError)
                }
                onMouseDown={
                  !slides[currentSlide + 1]
                    ? () => {
                        createNewCheckIn()
                      }
                    : () => {
                        setCurrentSlide(currentSlide + 1)
                      }
                }
              >
                {!slides[currentSlide + 1] ? "Save" : "Next"}
              </LoadingButton>
            </div>
          </CardActions>
        </Paper>
      </div>
      <Dialog
        open={discardDialogOpen}
        onClose={handleDiscardDialogCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to close this window?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            All entered data will be lost
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDiscardDialogCancel}>Cancel</Button>
          <Button onClick={handleDiscardDialogClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* change team dialog */}
      <ChangeTeamDialog
        open={changeTeamDialogOpen}
        setOpen={setChangeTeamDialogOpen}
        selectedTeam={selectedTeam}
        setSelectedTeam={setSelectedTeam}
        setTeam={setTeam}
      />
      {/* change checkin group dialog */}
      <ChangeCheckInGroupDialog
        open={changeCheckInGroupDialogOpen}
        setOpen={setChangeCheckInGroupDialogOpen}
        selectedCheckInGroup={selectedCheckInGroup}
        setSelectedCheckInGroup={setSelectedCheckInGroup}
        setCheckInGroup={setCheckInGroup}
      />
      <LoadingBackdrop open={loading} />
    </Dialog>
  )
}

export default CreateCheckInDialog
