import { Alert, LinearProgress, Snackbar } from "@mui/material"
import { useCallback, useContext, useEffect, useState } from "react"
import ListContainer from "../../components/global/listContainer"
import ListTable from "../../components/global/listTable"
import TableSpinner from "../../components/global/tableSpinner"
import { MainContext } from "../../controllers/main"
import { useLocation } from "react-router-dom"
import { CheckInsContext } from "../../controllers/checkIns"
import CheckInsListTopBar from "../../components/checkIns/checkInsListTopBar"
import CreateCheckInDialog from "../../components/checkIns/createCheckInDialog"
import CheckInsListRow from "../../components/checkIns/checkInsListRow"

const CheckInsList = () => {
  const { showPadding } = useContext(MainContext)
  const {
    loading,
    updatingList,
    setUpdatingList,
    checkInsList,
    getCheckInsList,
    checkInsListNextToken,
    loadMoreCheckIns,
    hasSearched,
  } = useContext(CheckInsContext)
  const location = useLocation()

  // list background update
  const backgroundUpdate = () => {
    if (!checkInsList.length) {
      getCheckInsList()
    } else if (!loading && !hasSearched) {
      setUpdatingList(true)
      getCheckInsList(false)
    }
  } // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    backgroundUpdate()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // handle create dialog
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)

  const handleDialogChange = useCallback(() => {
    setDialogOpen(!dialogOpen)
  }, [dialogOpen])

  // load more button loading
  const [loadMoreLoading, setLoadMoreLoading] = useState<boolean>(false)

  // handle checkIn deleted feedback
  const [checkInDeletedFeedbackOpen, setCheckInDeletedFeedbackOpen] =
    useState<boolean>(false)

  useEffect(() => {
    if (location.state && (location.state as any).checkInDeleted) {
      setCheckInDeletedFeedbackOpen(true)
    }
  }, [])

  return (
    <ListContainer style={{ position: "relative" }}>
      {updatingList && (
        <LinearProgress
          style={{
            position: "absolute",
            top: 88,
            width: "100%",
            zIndex: 10,
          }}
        />
      )}
      <CheckInsListTopBar addButtonOnClick={handleDialogChange} />
      {loading ? (
        <TableSpinner
          height={
            showPadding === "yes"
              ? "calc(100vh - 150px)"
              : "calc(100vh - 118px)"
          }
        />
      ) : (
        <ListTable
          height={
            showPadding === "yes"
              ? "calc(100vh - 150px)"
              : "calc(100vh - 118px)"
          }
          headingItems={[
            "QR Code",
            "Title & ID",
            "Translations",
            "Status",
            "Difficulty",
            "Group",
            "Team",
            "Updated",
            "",
          ]}
          nextToken={checkInsListNextToken}
          loadingMoreItems={loadMoreLoading}
          setLoadingMoreItems={setLoadMoreLoading}
          loadMore={loadMoreCheckIns}
          tableBody={checkInsList.map((item) => (
            <CheckInsListRow key={item.id} item={item} />
          ))}
        />
      )}
      <CreateCheckInDialog
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        handleDialogChange={handleDialogChange}
        topBarText="Add Check-in"
      />
      <Snackbar
        open={checkInDeletedFeedbackOpen}
        onClose={() => {
          setCheckInDeletedFeedbackOpen(false)
        }}
        autoHideDuration={3000}
      >
        <Alert severity="success">Check-in deleted</Alert>
      </Snackbar>
    </ListContainer>
  )
}

export default CheckInsList
