import {
  AppBar,
  Button,
  Dialog,
  IconButton,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material"
import { Dispatch, SetStateAction } from "react"
import { DialogTransition } from "../../services/utilities/utility"
import { CloseRounded } from "@mui/icons-material"
import CheckInGroup from "../../models/checkInGroup"
import CheckInGroupsList from "../../views/checkInGroup/checkInGroupsList"

const ChangeCheckInGroupDialog = ({
  open,
  setOpen,
  selectedCheckInGroup,
  setSelectedCheckInGroup,
  setCheckInGroup,
}: {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  selectedCheckInGroup: CheckInGroup
  setSelectedCheckInGroup: Dispatch<SetStateAction<CheckInGroup>>
  setCheckInGroup: Dispatch<SetStateAction<CheckInGroup>>
}) => {
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => {
        setOpen(false)
        setSelectedCheckInGroup(null)
      }}
      TransitionComponent={DialogTransition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              setOpen(false)
              setSelectedCheckInGroup(null)
            }}
            aria-label="close"
          >
            <CloseRounded />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Select Check-in Group
          </Typography>
          <Button
            autoFocus
            color="inherit"
            onClick={() => {
              setCheckInGroup(selectedCheckInGroup)

              setOpen(false)
              setSelectedCheckInGroup(null)
            }}
            disabled={!selectedCheckInGroup}
          >
            Select
          </Button>
        </Toolbar>
      </AppBar>
      <Paper style={{ backgroundColor: "#f5f5f5" }}>
        <CheckInGroupsList
          forDialog
          selectedCheckInGroup={selectedCheckInGroup}
          setSelectedCheckInGroup={setSelectedCheckInGroup}
        />
      </Paper>
    </Dialog>
  )
}

export default ChangeCheckInGroupDialog
