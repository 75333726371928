import { LoadingButton } from "@mui/lab"
import { AutoFixHigh } from "@mui/icons-material"
import {
  Button,
  CardActions,
  CardContent,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
  Autocomplete,
  Dialog,
  Chip,
  Tooltip,
} from "@mui/material"
import {
  Dispatch,
  MouseEventHandler,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import {
  capitalizeFirstCharacter,
  DialogTransition,
} from "../../services/utilities/utility"
import DialogTopBar from "../global/dialogTopBar"
import LoadingBackdrop from "../global/loadingBackdrop"
import { MediaContext } from "../../controllers/media"
import { MediaJobsContext } from "../../controllers/mediaJobs"
import { MediaSize, JobStatus } from "../../services/config/enum"
import SingleUpload from "./singleUpload"
import MultipleUpload from "./multipleUpload"

const UploadMediaDialog = ({
  dialogOpen,
  handleDialogChange,
  topBarText,
  setMediaUploadedSnackbarOpen,
  forDialogCategory,
}: {
  dialogOpen: boolean
  handleDialogChange: MouseEventHandler<HTMLButtonElement>
  topBarText: string
  setMediaUploadedSnackbarOpen?: Dispatch<SetStateAction<boolean>>
  forDialogCategory: string | null
}) => {
  const {
    loading,
    uploadMedia,
    setLoading,
    mediaTagsList,
    mediaTagsLoading,
    mediaCategoriesList,
  } = useContext(MediaContext)
  const {
    loading: aiSuggestionsLoading,
    suggestMetadata,
    polledJob,
    stopPollingJob,
  } = useContext(MediaJobsContext)

  // prevent tab close
  const handleTabClose = useCallback((event: any) => {
    event.preventDefault()
    console.log("beforeunload event triggered")
    return (event.returnValue = "Are you sure you want to exit?")
  }, [])

  useEffect(() => {
    if (dialogOpen) {
      window.addEventListener("beforeunload", handleTabClose)
    } else {
      window.removeEventListener("beforeunload", handleTabClose)
    }
  }, [dialogOpen])

  // image infos
  const [imageTitle, setImageTitle] = useState<string>("")
  const [imageDescription, setImageDescription] = useState<string>("")
  const [mediaTags, setMediaTags] = useState<string[]>([])
  const [imageCategory, setImageCategory] = useState<string | null>(null)

  // single upload
  const [droppedImage, setDroppedImage] = useState<{
    name: string
    size: number
    dataUrl: string
  } | null>(null)
  const [droppedImageError, setDroppedImageError] = useState<boolean>(false)
  const [droppedImageErrorType, setDroppedImageErrorType] = useState<string>("")

  // multiple upload
  const [droppedImages, setDroppedImages] = useState<
    {
      name: string
      size: number
      dataUrl: string
      sizeString: MediaSize | null
      hasError: boolean
    }[]
  >([])

  // reset dialog on close
  useEffect(() => {
    if (!dialogOpen) {
      setTimeout(() => {
        setImageTitle("")
        setInvalidCharacterError(false)
        setMediaTags([])
        setDroppedImage(null)
        setImageCategory(null)
        setDroppedImageError(false)
        setDroppedImageErrorType("")
        setDroppedImages([])
        setImageDescription("") // Also reset description
      }, 100)
      // Stop any running job when dialog closes
      stopPollingJob()
    } else {
      setImageCategory(forDialogCategory)
    }
  }, [dialogOpen, stopPollingJob])

  // discard dialog
  const [discardDialogOpen, setDiscardDialogOpen] = useState<boolean>(false)

  const handleDiscardDialogCancel = () => {
    setDiscardDialogOpen(false)
  }

  const handleDiscardDialogClose = (e: any) => {
    // Stop any running job when discarding
    stopPollingJob()
    setDiscardDialogOpen(false)
    handleDialogChange(e)
  }

  // should show discard dialog
  const [showDiscardDialog, setShowDiscardDialog] = useState<boolean>(false)

  useEffect(() => {
    if (
      imageTitle.length ||
      mediaTags.length ||
      droppedImage ||
      droppedImages.length ||
      imageDescription.length
    ) {
      setShowDiscardDialog(true)
    } else {
      setShowDiscardDialog(false)
    }
  }, [imageTitle, mediaTags, droppedImage, droppedImages, imageDescription])

  // invalid character error
  const [invalidCharacterError, setInvalidCharacterError] =
    useState<boolean>(false)

  // Validate title with regex
  const validateTitle = (title: string) => {
    const regex = /^[a-zA-Z0-9\s-]+$/g
    return regex.test(title)
  }

  // Update form when AI suggestions are ready
  useEffect(() => {
    if (
      polledJob &&
      polledJob.status === JobStatus.COMPLETED &&
      polledJob.generatedMetadata
    ) {
      const metadata = polledJob.generatedMetadata
      if (metadata.title) {
        const title = metadata.title
        setImageTitle(title)
        // Validate title after AI suggestion
        setInvalidCharacterError(!validateTitle(title))
      }
      if (metadata.description) {
        setImageDescription(metadata.description)
      }
      if (metadata.mediaTags?.length > 0) {
        const validTags = metadata.mediaTags.filter((tag) =>
          mediaTagsList.some(
            (existingTag) => existingTag.toLowerCase() === tag.toLowerCase()
          )
        )
        if (validTags.length > 0) {
          setMediaTags(validTags)
        }
      }
    }
  }, [polledJob, mediaTagsList])

  return (
    <Dialog
      fullScreen
      open={dialogOpen}
      onClose={
        showDiscardDialog
          ? () => {
              setDiscardDialogOpen(true)
            }
          : handleDialogChange
      }
      TransitionComponent={DialogTransition}
    >
      <DialogTopBar
        handleDialogChange={
          showDiscardDialog
            ? () => {
                setDiscardDialogOpen(true)
              }
            : handleDialogChange
        }
        topBarText={topBarText}
      />
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          variant="outlined"
          style={{
            width: "70%",
            minWidth: 345,
            maxWidth: 850,
            height: 600,
            position: "relative",
          }}
        >
          <Typography
            variant="h4"
            textAlign="center"
            style={{ paddingTop: 16 }}
          >
            {topBarText}
          </Typography>
          <CardContent>
            <Stack spacing={2}>
              <Stack direction="row" spacing={2}>
                <FormControl fullWidth size="small">
                  <InputLabel id="type-select">Type</InputLabel>
                  <Select
                    labelId="type-select"
                    label="Type"
                    value={imageCategory ? imageCategory : ""}
                    disabled={aiSuggestionsLoading}
                    onChange={(e: any) => {
                      setImageCategory(e.target.value)
                      setDroppedImage(null)
                      setDroppedImages([])
                    }}
                  >
                    {mediaCategoriesList.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item === "actionGroup"
                          ? "Series"
                          : item === "actionGroupBadge"
                          ? "Series Badge"
                          : capitalizeFirstCharacter(item.toString())}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Autocomplete
                  fullWidth
                  size="small"
                  limitTags={2}
                  multiple
                  value={mediaTags}
                  onChange={(event, newValues: string[]) => {
                    setMediaTags(newValues)
                  }}
                  loading={mediaTagsLoading}
                  disabled={aiSuggestionsLoading}
                  getOptionLabel={(option) => {
                    if (option === "1stilllife") {
                      return "1. Still Life"
                    } else if (option === "2composition") {
                      return "2. Composition"
                    } else {
                      return capitalizeFirstCharacter(option)
                    }
                  }}
                  options={mediaTagsList}
                  renderInput={(params) => (
                    <TextField {...params} label="Tags" />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} key={option}>
                      {option === "1stilllife"
                        ? "1. Still life"
                        : option === "2composition"
                        ? "2. Composition"
                        : capitalizeFirstCharacter(option)}
                    </li>
                  )}
                  renderTags={(tagValue, getTagProps) => {
                    return tagValue.map((option, index) => (
                      <Chip
                        {...getTagProps({ index })}
                        size="small"
                        key={option}
                        label={
                          option === "1stilllife"
                            ? "1. Still life"
                            : option === "2composition"
                            ? "2. Composition"
                            : capitalizeFirstCharacter(option)
                        }
                      />
                    ))
                  }}
                />
              </Stack>
              <TextField
                size="small"
                label="Title"
                fullWidth
                value={imageTitle}
                disabled={aiSuggestionsLoading}
                onChange={(e) => {
                  const value = capitalizeFirstCharacter(e.target.value)
                  setImageTitle(value)
                  setInvalidCharacterError(!validateTitle(value))
                }}
                error={invalidCharacterError}
                helperText={
                  invalidCharacterError && !imageTitle.length
                    ? "Title cannot be empty"
                    : invalidCharacterError
                    ? "One or more invalid characters"
                    : ""
                }
                onBlur={() => {
                  if (imageTitle) {
                    const trimmed = imageTitle.trim()
                    setImageTitle(trimmed)
                    setInvalidCharacterError(!validateTitle(trimmed))
                  }
                }}
              />
              <TextField
                size="small"
                label="Description (optional)"
                fullWidth
                multiline
                disabled={aiSuggestionsLoading}
                rows={3}
                value={imageDescription}
                onChange={(e) => {
                  setImageDescription(capitalizeFirstCharacter(e.target.value))
                }}
                onBlur={() => {
                  if (imageDescription) {
                    setImageDescription((current) => current.trim())
                  }
                }}
              />
              {imageCategory === "content" ? (
                <MultipleUpload
                  droppedImages={droppedImages}
                  setDroppedImages={setDroppedImages}
                  imageCategory={imageCategory}
                  height={invalidCharacterError ? "202px" : "228px"}
                />
              ) : (
                <SingleUpload
                  droppedImage={droppedImage}
                  setDroppedImage={setDroppedImage}
                  droppedImageError={droppedImageError}
                  setDroppedImageError={setDroppedImageError}
                  droppedImageErrorType={droppedImageErrorType}
                  setDroppedImageErrorType={setDroppedImageErrorType}
                  imageCategory={imageCategory}
                  height={invalidCharacterError ? "202px" : "228px"}
                />
              )}
            </Stack>
          </CardContent>
          <CardActions
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              padding: 16,
              width: "100%",
            }}
          >
            <div style={{ width: "50%" }}>
              <Button
                variant="outlined"
                onClick={
                  showDiscardDialog
                    ? () => {
                        setDiscardDialogOpen(true)
                      }
                    : handleDialogChange
                }
              >
                Close
              </Button>
            </div>
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "flex-end",
                gap: "8px",
              }}
            >
              <Tooltip title="Use AI to suggest metadata based on the image">
                <span>
                  <LoadingButton
                    variant="outlined"
                    startIcon={<AutoFixHigh />}
                    disabled={!droppedImage && !droppedImages[0]}
                    loading={aiSuggestionsLoading}
                    onClick={() => {
                      if (imageCategory === "content" && droppedImages[0]) {
                        suggestMetadata({
                          title: imageTitle || undefined,
                          fileType: droppedImages[0].name.slice(
                            droppedImages[0].name.lastIndexOf(".") + 1
                          ),
                          file: droppedImages[0],
                        })
                      } else if (droppedImage) {
                        suggestMetadata({
                          title: imageTitle || undefined,
                          fileType: droppedImage.name.slice(
                            droppedImage.name.lastIndexOf(".") + 1
                          ),
                          file: droppedImage,
                        })
                      }
                    }}
                  >
                    AI Suggest
                  </LoadingButton>
                </span>
              </Tooltip>
              <LoadingButton
                loading={loading}
                disabled={
                  (!droppedImage && !droppedImages.length) ||
                  droppedImageError ||
                  !imageTitle.length ||
                  invalidCharacterError ||
                  !mediaTags.length ||
                  droppedImages.filter((item) => item.hasError).length > 0
                }
                variant="contained"
                onClick={async (e) => {
                  var noErrors = false

                  if (droppedImage) {
                    noErrors = await uploadMedia(
                      imageTitle.trim(),
                      mediaTags,
                      imageCategory,
                      null,
                      droppedImage,
                      imageDescription.length ? imageDescription.trim() : null,
                      droppedImage.name.slice(
                        droppedImage.name.lastIndexOf(".") + 1
                      )
                    )
                  } else if (droppedImages.length) {
                    let calls = []
                    for (let i = 0; i < droppedImages.length; i++) {
                      if (i === droppedImages.length - 1) {
                        calls.push(
                          uploadMedia(
                            imageTitle.trim() +
                              "-" +
                              droppedImages[i].sizeString,
                            mediaTags,
                            imageCategory,
                            droppedImages[i].sizeString,
                            droppedImages[i],
                            imageDescription.length
                              ? imageDescription.trim()
                              : null,
                            droppedImages[i].name.slice(
                              droppedImages[i].name.lastIndexOf(".") + 1
                            )
                          )
                        )
                      } else {
                        calls.push(
                          uploadMedia(
                            imageTitle.trim() +
                              "-" +
                              droppedImages[i].sizeString,
                            mediaTags,
                            imageCategory,
                            droppedImages[i].sizeString,
                            droppedImages[i],
                            imageDescription.length
                              ? imageDescription.trim()
                              : null,
                            droppedImages[i].name.slice(
                              droppedImages[i].name.lastIndexOf(".") + 1
                            )
                          )
                        )
                      }
                    }
                    let noErrorsArray = await Promise.all(calls)
                    if (!noErrorsArray.filter((item) => !item).length) {
                      noErrors = true
                    }
                  } else {
                    return
                  }

                  if (noErrors) {
                    setLoading(false)
                    setImageDescription("") // Reset description before closing
                    handleDialogChange(e)
                    setMediaUploadedSnackbarOpen(true)
                  } else {
                    setLoading(false)
                  }
                }}
              >
                Upload
              </LoadingButton>
            </div>
          </CardActions>
        </Paper>
      </div>
      {/* discard alert dialog */}
      <Dialog
        open={discardDialogOpen}
        onClose={handleDiscardDialogCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to close this window?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            All entered data will be lost
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDiscardDialogCancel}>Cancel</Button>
          <Button onClick={handleDiscardDialogClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <LoadingBackdrop open={loading} />
    </Dialog>
  )
}

export default UploadMediaDialog
